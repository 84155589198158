.convert-box{
    width: 100%;
    height: 360px;
    /* border: 1px solid red; */
    
    border-radius: 5px;
}
/* option_style */
.convert-box .option_style {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 100%;
    font-size: 12px;
    /* background: #00c0af; */
}
/* برای همه جا */
.option_style {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 100%;
    font-size: 12px;
    /* background: #00c0af; */
}
/* //////////////////////////////////////////////// */
.convert-box .two_btn{
    display: flex;
    column-gap: 5px;
    height: 38px;
    /* border: 1px solid red; */
    margin-bottom: 10px;
}
.convert-box .two_btn>button{
    height: 100%;
  
    border-radius: 5px;
    padding: 7px 10px;
}
/* //////////////////////////////////////////////// */
.show-min-max {
    direction: ltr;
    position: absolute;
    bottom: -20px;
    color: red;
    font-size: 12px;
    /* border: 1px solid red; */
}

.show-error {
    position: absolute;
    bottom: -20px;
    color: red;
    font-size: 12px;
    /* border: 1px solid red; */
    
}


 
#dark .convert-box .user-balance {
    color: #fff;
}