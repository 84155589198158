#agree-section-one {
  padding: 4rem 0;
}
#dark #agree-section-one {
 color: #fff;
}
#agree-section-one .back {
  display: flex;
  align-items: center;
  padding: 12px 25px;
  border: 1px solid #edeef1;
  border-radius: 10px;
  position: absolute;
  top: 4rem;
  left: 2rem;
  cursor: pointer;
}
#dark #agree-section-one .back {
  color: #fff;
}
#agree-section-one .back img {
  margin-right: 5px;
}
#agree-section-one .all {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#agree-section-one .all .title {
  font-family: shabnam-bold;
  font-size: 24px;
  padding-bottom: 1rem;
}
#agree-section-one .all .des {
  font-family: shabnam-light;
  font-size: 20px;
  text-align: center;
  width: 600px;
}
#agree-section-one .bottom {
  font-family: shabnam-light;
  font-size: 16px;
  padding: 16px 40px;
  border: 1px dashed #00c0af;
  border-radius: 10px;
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
}

@media only screen and (max-width: 1024px) {
  #agree-section-one .all .des {
    width: 400px;
  }
  #agree-section-one .bottom {
    bottom: 10rem;
  }
  #agree-section-one .all{
    bottom: 90px;
  }
}

@media only screen and (max-width: 640px) {
    #agree-section-one .all .des {
      width: 80%;
    }
    #agree-section-one .back {
        display: none;
    }
  }
  