#verification{
    /* border: 2px solid red; */
  }
  .grand-parent{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 100vh;
      /* border: 2px solid red; */
  }
  .verification-parent{
      display: flex;
      flex-wrap: wrap !important;
      height: 565px;
      /* border: 1px solid red; */
      border-radius: 40px;
      overflow: hidden;
      box-shadow: 1px 1px 5px rgb(193, 190, 190);
  }
  
  /* right */
  /* right */
  
  .verification-right{
      width: 50%;
      height: 100%;
      padding: 14px;
  }
  .verification-right >.back{
      width: 100%;
      /* border: 1px solid red; */
      padding: 0 10px;
      margin: 20px 0;
  }
  .verification-right>.back svg{
      font-size: 25px;
      font-weight: normal !important;
      transform: scaleX(1.3);
      
  }
  .verification-right figure{
      
      /* border: 1px solid red; */
      margin-bottom: 10px;
  }
  .verification-right h1{
      text-align: center;
      /* border: 1px solid red; */
      margin-bottom: 10px;
  }
  .verification-right  p{
      text-align: center;
      /* border: 1px solid red; */
      color: #707070;
  }
  
  .verification-right form{
      position: relative;
      height: 250px;
      margin-top: 15px;
      /* border: 1px solid red; */
  } 
  .verification-right form .mini-parent{
      position: relative;
      width: 100%;
      height: 72px;
      /* border: 1px solid black; */
      margin-bottom: 15px;
  }
  .verification-right form .mini-parent label{
      display: flex;
      height: 19px;
      font-size: 14px;
      color:#a5a5a5 ;
      margin-bottom: 7px;
  }
  .verification-right form .mini-parent input{
      width: 100%;
      height: 46px;
      border-radius: 10px;
      border: 1px solid #e3e4f5;
  }
  /* button submit */
  /* button submit */
  .verification-right form .submit-form{
      width: 100%;
      height: 46px !important;
  }
  .verification-right form .submit-form button{
      width: 100%;
      height: 100%;
      background:#f9cc0b ;
      color: white;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 400;
  }
  /* recently *//* recently */
  /* recently *//* recently */
  .verification-right form > .recently{
      position: absolute;
      bottom: 0px ; right: 0;
      margin-bottom: 0 !important;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 46px !important;
      font-size: 14px;
      border-top: 1px solid #e3e4f5;
      /* border: 1px solid #e3e4f5; */
  }
  .verification-right .recently span a{
      color: #f6b33f;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* responsive ریسپانسیو */
  /* responsive ریسپانسیو */
  
  /* MAX 640 */
  @media screen and (max-width: 640px) {
       body{
          /* background: rgb(209, 200, 204); */
       }
       .verification-parent{
          align-content: flex-start;
          width: 90%;
          justify-content: center;
          height: 520px;
          /* border: 1px solid black; */
          border-radius: 20px;
       }
       
      
     
     
       .verification-right{
          width: 90%;
          height: 400px;
          order: 1;
          /* border: 1px solid orange; */
          padding: 0;
      }
   
      .verification-right .back{
          /* border: 1px solid red; */
          margin-bottom: 0;
          margin-top: 15px;
      }
      .verification-right figure{
          /* border: 1px solid red; */
          margin-bottom: 5px;
      }
      .verification-right form .recently{
          position: fixed;
          width: 90%;
          right: 5%;
          bottom: 20px;
          /* border: 1px solid red; */
      }
  
  
  
  
  
  }
  /* MIN 640 */
  @media screen and (min-width: 640px) {
      body{
          /* background: rgb(112, 216, 176); */
       }
      .verification-parent{
        width: 90% !important;
        max-width: 704px !important;
      }
  }
  @media screen and (min-width: 768px) {
  
  }
  @media screen and (min-width: 1024px) {
      body{
          /* background: rgb(206, 164, 86); */
       }
       .verification-parent{
          width: 842px !important;
          max-width: 842px !important;
        }
  }
  @media screen and (min-width: 1280px) {
  
  }
  @media screen and (min-width: 1536px) {
  
  }
  
  