#ab1{
    width: fit-content !important;
    height: 35px;
    margin: auto;
    padding: 15px;
    background: #d8f3f1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #00c0af;
    
    cursor: pointer;
}

#everything .body {
    background: #fff;
    border-radius: 20px;
    min-height: 10rem;
}
#dark #everything .body {
    background: #000;
}

#everything .body.order {
    padding: 1rem;
    overflow-x: auto;
}