/* //////////// judge-right ///////////////////*/
#light .judge-right{
    background: white ;
}
#dark .judge-right{
    background: black ;
}
/* //////////////// h1 ///////////////////////*/
#light .judge-right h1{
    color: black;
}
#dark .judge-right h1{
   color: white;
}



/* //////////////submit button///////////// */
#light .judge-right form .submit-form button{
    background: var(--light-yellow-1);
    color: white;
}
#dark .judge-right form .submit-form button{
    background: var(--dark-yellow-1);
    color: white;
}
