#transaction-return #countdown {
    --persent: 0;
    position: relative;
    width: 100px;
    height: 100px;
    background: conic-gradient(from 0deg, var(--color) 0%, var(--color) 0% var(--percent), #eee var(--percent), #eee 100%);
    border-radius: 50%;
    margin: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#transaction-return #countdown::before {
    content: '';
    position: absolute;
    inset: 8px;
    border-radius: inherit;
    background-color: white;
}

#transaction-return #countdown span {
    font-weight: 600;
    font-size: 20px;
    z-index: 1;
}