.change-language{
  
    /* border: 1px solid red; */
   
}
.change-language .mehdi_option_style{
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 100%;
    font-size: 12px;
}

