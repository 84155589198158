.lightingMode{
    direction: rtl !important;
    position: absolute;
    width: 63.14px !important;
    height: 30px !important;
    /* border: 1px solid red; */
}
.lightingMode>main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    /* background: green; */
    background: #f6b33f;
    padding: 0 3px;
    cursor: pointer;
    user-select: none;
}
.lightingMode>main>section{
    width: 24px;height: 24px;
    /* background: gray; */
    border-radius: 50%;
    
}