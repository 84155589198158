/* ////////////////////سمت راست///////////////////////// */
#light .desktop-nav>div:nth-of-type(1)>section{
    background: white;
}
#dark .desktop-nav>div:nth-of-type(1)>section{
   background: black;
}
/* //////////////////////سمت راست/////////////////////// */
#light .desktop-nav>div:nth-of-type(1)>section>span{
    color: black;
}
#dark .desktop-nav>div:nth-of-type(1)>section>span{
   color: white;
}
/* //////////////////////سمت چپ/////////////////////// */
#light .desktop-nav>div:nth-of-type(2)>span:nth-of-type(1){
    background: white;
    
}
#dark .desktop-nav>div:nth-of-type(2)>span:nth-of-type(1){
   background: black;
   
}
/* //////////////////////سمت چپ/////////////////////// */
#light .desktop-nav>div:nth-of-type(2)>span:nth-of-type(2){
    color: var(--light-gray-1);
    
}
#dark .desktop-nav>div:nth-of-type(2)>span:nth-of-type(2){
    color: var(--dark-gray-1);
   
}
/* //////////////////////سمت چپ/////////////////////// */
#light .desktop-nav>div:nth-of-type(2)>span:nth-of-type(3){
    color: black;
    
}
#dark .desktop-nav>div:nth-of-type(2)>span:nth-of-type(3){
    color: white;
   
}
/* ////////////////////// لیست اعلانات برای تبلت و دسکتاپ/////////////////////// */
#light ul.notification-list{
    background: white;
    color: black;
}
#dark ul.notification-list{
    background: black;
    color: white;
   
}

/* ////////////////////// سه نقطه/////////////////////// */
#light ul.list_three_dots {
    background: white;
    color: black;

}
#dark ul.list_three_dots {
    background: black;
    color: white;
}
/* //////////////////////پروفایل منو/////////////////////// */
#light ul.profile_menu {
    background: white;
    color: black;

}
#dark ul.profile_menu {
    background: black;
    color: white;
}
   