#aboutus-section-one {
  padding: 2rem 0;
}
#aboutus-section-one .all {
  width: 75%;
  height: 454px;
  margin: auto;
  background: url(../image/about-us-page/Hero3.png);
  background-size: cover;
  position: relative;
  border-radius: 40px;
}

#aboutus-section-one .all img {
  position: absolute;
  top: 35px;
  left: 50px;
}
#aboutus-section-one .all .text1 {
  position: absolute;
  top: 155px;
  left: 70px;
  color: var(--maincolor2);
  font-size: 18px;
  font-family: Poppins-Regular;
  /* width: 83px; */
}
.ar #aboutus-section-one .all .text1 {

  left: 320px;
  font-family: shabnam-light;
}
#aboutus-section-one .all .text2 {
  width: 390px;
  position: absolute;
  top: 195px;
  left: 50px;
  font-size: 27px;
  color: #fff;
  font-family: Poppins-Bold;
}
.ar #aboutus-section-one .all .text2 {
  font-family: shabnam-Bold;
}
/* Aboutus-section-two */
#aboutus-section-two {
  padding: 4rem 0;
}
#aboutus-section-two .all {
  width: 75%;
  margin: auto;
}
#dark #aboutus-section-two .all {
  color: #fff;
}
#aboutus-section-two .all .part2{
  margin-top: 2rem;
}
#aboutus-section-two .all .title {
  padding-left: 10px;
  padding-bottom: 2rem;
  font-size: 24px;
  font-family: Poppins-Regular;
}
.ar #aboutus-section-two .all .title {
  padding-right: 10px;
  font-family: shabnam-light;
}
#aboutus-section-two .all .des {
  font-family: Poppins-Regular;
  text-align: justify;
}
#aboutus-section-two .all .des {
  font-family:shabnam-light;
}
/* aboutus-section-three */
#aboutus-section-three {
  padding: 2rem 0;
}
#aboutus-section-three .all {
  width: 75%;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto;
}
#dark #aboutus-section-three .all {
  color: #fff;
}
#aboutus-section-three .all .grid-item {
  padding: 2rem;
}
#aboutus-section-three .all .carts {
  position: relative;
  text-align: center;
}
#aboutus-section-three .all .number {
  font-size: 38px;
  font-family: Poppins-Bold;
}
#aboutus-section-three .all .title {
  font-size: 17px;
  font-family: Shabnam;
}
#aboutus-section-three .all .grid-item .opacity {
  opacity: 0.2;
}
#aboutus-section-three .all img {
  position: absolute;
  top: 0;
  right: 75px;
}
.team-img {
  width: 75%;
  margin: auto;
  padding-bottom: 5rem;
}
.team-img img {
  width: 100%;
  margin: auto;
}

/* responsive */
@media only screen and (max-width: 1200px) {
  #aboutus-section-one .all {
    width: 90%;
  }
  #aboutus-section-two .all {
    width: 90%;
  }
  #aboutus-section-three .all {
    width: 90%;
  }
}
@media only screen and (max-width: 992px) {
  .ar #aboutus-section-one .all .text1{
    left: 150px;
  }
  #aboutus-section-one {
    padding: unset;
    background: linear-gradient(#2a303a, #3a2d16);
    height: 281px;
  }
  #aboutus-section-one .all {
    width: 100%;
    background: url(../image/about-us-page/Hero3.png);
    background-size: contain;
    background-position: right top;
    background-repeat: no-repeat;
    border-radius: unset;
  }
  #aboutus-section-one .all img {
    top: 10px;
    left: 30px;
  }
  #aboutus-section-one .all .text1 {
    top: 90px;
    left: 50px;
  }
  #aboutus-section-one .all .text2 {
    width: 300px;
    top: 120px;
    left: 30px;
    font-size: 23px;
  }
}
@media only screen and (max-width: 768px) {
  .ar #aboutus-section-one .all img{
    right: unset;
    left: 50px;
  }
  #aboutus-section-two .all .title {
    padding-left: 10px;
    padding-bottom:0;
  }
  #aboutus-section-two .all .part2 {
    margin-top: 50px;
  }
  #aboutus-section-two{
    padding: 0;
  }
  #aboutus-section-three .all {
    grid-template-columns: auto;
  }
  #aboutus-section-one {
    height: 403px;
  }
  #aboutus-section-one .all {
    background: url(../image/about-us-page/about-img-mobile2.png);
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: unset;
    height: 100%;
  }
  .ar #aboutus-section-one .all {
    background: url(../image/about-us-page/about-img-mobile2.png);
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: unset;
    height: 100%;
  }
  #aboutus-section-one .all img {
    position: absolute;
    top: 20px;
    right: 50px;
    left: unset;
  }
  #aboutus-section-one .all .text1 {
    top: 50px;
    right: 25px !important;
    font-size: 16px;
  }
  #aboutus-section-one .all .text2 {
    width: 80%;
    top: 105px;
    left: 50px;
    font-size: 18px;
  }
}
