button {
    background-color: unset;
    border: none;
    cursor: pointer;
    /* font-family: Poppins-Regular; */
}

/* /////////////////////////////////////////////////// ex-top */
.ex-container {
    min-height: 100vh;
    background-color: var(--light-gray-4);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 0 0 3rem 3rem;
}

.ex-top {
    background-color: #fff;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 2.5rem;
    flex-wrap: wrap;
}

.ex-top-one {
    display: flex;
    gap: 2.5rem;
}

.ex-top-coin {
    position: relative;
}

.ex-selectbox {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}

.coin-selectbox {
    position: absolute;
    top: 4.5rem;
    left: 0;
    border: 1px solid #eee;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    gap: 1px;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 1px 10px #00000022;
    animation: show .2s forwards;
    max-height: 65vh;
    overflow-y: auto;
    z-index: 20;
}

@keyframes show {
    0% {
        opacity: .5;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.coin-selectbox-option {
    background-color: #fff;
    display: flex;
    gap: 12px;
    padding: 1rem;
    font-size: 15px;
    align-items: center;
    cursor: pointer;
}

.coin-selectbox-option:hover {
    background-color: #fafafa;
}

.ex-top-coin img {
    width: 55px;
    border-radius: 50%;
}

.coin-selectbox-option img {
    width: 45px;
    border-radius: 50%;
}

.ex-select {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.ex-select-coin-top {
    font-weight: bold;
}

.ex-select-coin-bottom {
    color: #999;
    font-size: 15px;
}

.ex-select-arrow {
    transition: .2s;
}

.ex-top-price {
    font-weight: bold;
    color: var(--maincolor3);
    display: flex;
    align-items: center;
    font-size: 19px;
}

.ex-top-two {
    display: flex;
    gap: 4rem;
    justify-content: space-between;
}

.ex-top-two-title {
    color: #aaa;
    font-size: 13px;
}

.ex-top-two-num-changes {
    color: var(--maincolor3);
}

.ex-top-two-num-changes.down {
    color: var(--maincolorred) !important;
    direction: ltr !important;
}

.ex-top-two-num-changes.up {
    color: #15de5f !important;
}

.ar .ex-top-two-num-changes.up {
    direction: ltr;
}

.ex-top-three {
    flex-grow: 1;
    display: flex;
    justify-content: end;
    gap: 5px;
}

.ex-top-three>div {
    width: 2.6rem;
    height: 2.6rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ex-top-three>div:hover {
    border-color: #bbb;
}

.ex-bottom {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.ex-bottom>div {
    background-color: #fff;
    border-radius: 20px;
    height: 35rem;
}

.ex-chart,
.ex-orders {
    flex-grow: 1;
    max-width: calc(100% - (430px + 1rem));
    min-width: 50vw;
}

.ex-trading,
.ex-volumes {
    width: 430px;
}

.ex-top-price-mobile {
    display: none;
}

@media screen and (max-width: 1415px) {
    .ex-top-two {
        order: 2;
        width: 100%;
        gap: 1rem;
    }
}

@media screen and (max-width: 1300px) {
    .ex-container {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .ex-chart,
    .ex-trading,
    .ex-orders,
    .ex-volumes {
        width: 100%;
        max-width: unset;
    }

    .ex-orders {
        order: 2;
    }

    .ex-volumes {
        order: 1;
    }
}

@media screen and (max-width: 768px) {
    .ex-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .ex-top {
        background-color: unset;
    }

    .ex-top-one {
        flex-grow: 1;
    }

    .ex-top-coin {
        background-color: #fff;
        border-radius: 10px;
        padding: 7px 10px;
        width: 100%;
    }

    .ex-select {
        width: 100%;
        justify-content: space-between;
        padding-right: 1rem;
    }

    .ex-top-price {
        display: none;
    }

    .ex-top-two {
        flex-wrap: wrap;
        justify-content: start;
        gap: 1.5rem;
    }

    .ex-top-price-mobile {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: var(--maincolor3);
    }

    .ex-top-three {
        flex-grow: unset;
    }

    .ex-top-three>div {
        border-color: #ddd;
        width: 3rem;
        height: 3rem;
        background-color: #fcfcfc;
    }
}


/* //////////////////////////////////////////////// ex-chart */
.ex-chart {
    overflow: hidden;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ex-chart-top {
    display: flex;
    gap: 1rem;
}

.ex-chart-top button {
    padding: .8rem 1rem;
    border-radius: 10px;
}

.ex-chart-top button:hover {
    background-color: #fafafa;
}

.ex-chart-top button.active {
    background-color: var(--maincolor2light);
    color: var(--maincolor2);
    font-weight: bold;
    pointer-events: none;
}

.ex-chart iframe {
    width: 100%;
    flex-grow: 1;
}


/* /////////////////////////////////////////////// ex-trading */
.ex-trading {
    padding: 1.3rem 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.ex-tading-top {
    display: flex;
    justify-content: space-between;
}

.ex-trading-top-left {
    font-weight: bold;
    position: relative;
    padding-left: 1rem;
    display: flex;
    align-items: center;
}

.ar .ex-trading-top-left {
    padding-left: 0;
    padding-right: 1rem;
}

.ex-trading-top-left::before {
    content: '';
    position: absolute;
    left: 0;
    width: 3px;
    height: 100%;
    border-radius: 999px;
    background-color: var(--maincolor2);
}

.ar .ex-trading-top-left::before {
    left: unset;
    right: 0;
}

.ex-trading-top-right {
    display: flex;
    gap: 4px;
}

.ex-trading-top-right button {
    font-weight: bold;
    padding: 0 2rem;
    height: 2.8rem;
    border-radius: 10px;
    position: relative;
}

.ex-trading-top-right button.active {
    pointer-events: none;
}

.ex-trading-top-right button:first-child {
    color: var(--maincolorred);
    border: 1px solid var(--maincolorred);
}

.ex-trading-top-right button:first-child.active {
    background-color: var(--maincolorred) !important;
    color: #fff;
}

/* .ex-trading-top-right button:first-child:hover {
    background-color: #fff4f4;
} */

.ex-trading-top-right button:last-child {
    color: var(--maincolor3);
    border: 1px solid var(--maincolor3);
}

.ex-trading-top-right button:last-child.active {
    background-color: var(--maincolor3);
    color: #fff;
}

/* .ex-trading-top-right button:last-child:hover {
    background-color: #e6fffd;
} */

.ex-trading-middle {
    color: #aaa;
    padding: 1rem 0;
    font-size: 16px;
}

.ar .ex-trading-middle {
    display: flex;
    width: fit-content;
}

.ex-trading-middle span {
    cursor: pointer;
    padding: 0 .5rem 3px .5rem;
    transition: .1s;
}

.ex-trading-middle span:hover {
    color: #777;
}

.ex-trading-middle span.active {
    color: var(--maincolor2);
    pointer-events: none;
    font-weight: bold;
    border-bottom: 1px solid var(--maincolor2);
}

.ex-tading-bottom {
    display: flex;
    flex-grow: 1;
    gap: 1rem;
    flex-direction: column;
    justify-content: space-between;
}

.ex-tading-bottom>div {
    flex-grow: 1;
    border-radius: 10px;
}

.ex-t-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F6F7F9;
    padding: 0 1rem;
    border: 1px dashed #e0e2e6;
    color: #555;
    font-size: 15px;
    cursor: default;
}

.ex-t-number {
    position: relative;
}

.ex-t-number span:last-child {
    position: absolute;
    right: 1rem;
    bottom: 11px;
}

.ar .ex-t-number span:last-child {
    right: unset;
    left: 1rem;
}

.ex-t-amount,
.ex-t-number {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.ex-t-amount span,
.ex-t-number span {
    color: #aaa;
    font-size: 15px;
}

.ex-t-amount input,
.ex-t-number input {
    width: 100%;
    flex-grow: 1;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 0 1rem;
    font-size: 18px;
    outline: none;
}

.ex-t-number input {
    padding-right: 55px;
}

.ar .ex-t-number input {
    padding-right: 1rem;
    padding-left: 55px;
}

.ex-t-amount input:disabled,
.ex-t-number input:disabled {
    pointer-events: none;
    opacity: 0.5;
}

.ex-t-amount input:hover,
.ex-t-number input:hover,
.ex-t-amount input:focus,
.ex-t-number input:focus {
    border-color: #aaa;
}

.ex-t-amount {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.ex-t-amount>div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
}

.ex-t-radio {
    flex-grow: 0 !important;
    margin: 5px 0;
}

.ex-t-radio input {
    width: 100%;
}

/* .ex-t-radio input[type="range"]::-webkit-slider-runnable-track {
    background: var(--maincolor2);
    height: 0.2rem;
}

.ex-t-radio input[type="range"]::-moz-range-track {
    background: var(--maincolor2);
    height: 0.2rem;
} */

.ex-t-radio {
    text-align: center;
}

.ex-t-radio span {
    text-align: center;
    font-size: 18px;
    color: #777;
    margin: 0 2px;
    margin-top: 5px;
}

.ex-t-radio span:last-child {
    font-size: 14px;
    color: #aaa;
}

.single-thumb .range-slider__range {
    border-radius: 6px;
}

.range-slider__thumb {
    background-color: var(--maincolor2) !important;
    width: 18px !important;
    height: 18px !important;
}

.range-slider__range {
    background-color: var(--maincolor2) !important;
}

.single-thumb .range-slider__thumb[data-lower] {
    width: 0 !important;
}

.ex-t-total {
    display: flex;
    justify-content: space-between;
    border: 2px dashed #ddd;
    align-items: center;
    padding: 0 1rem;
    color: #555;
}


.ex-tading-bottom>button:disabled {
    opacity: 0.5;
}

.ex-t-buynow {
    background-color: var(--maincolor3);
    color: #fff;
    border-radius: 10px;
    font-weight: bold;
    height: 2.8rem;
    box-shadow: 0 1px 5px #00000022;
}

.ex-t-buynow:hover {
    background-color: var(--maincolor3hover);
}

.ex-t-sellnow {
    background-color: var(--maincolorred);
    color: #fff;
    border-radius: 10px;
    font-weight: bold;
    height: 2.8rem;
    box-shadow: 0 1px 5px #00000022;
}

.ex-t-sellnow:hover {
    background-color: #f02b2b;
}

.ex-trading-overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff99;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    text-align: center;
    backdrop-filter: blur(1px);
}

.ex-trading-overlay>div {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.ex-t-o-register,
.ex-t-o-login {
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    width: 11rem;
    padding: .7rem 0;
    border-radius: 12px;
    text-align: center;
}

.ex-t-o-register {
    border: 2px solid var(--maincolord2);
    color: var(--maincolord2);
}

.ex-t-o-register:hover {
    border-color: var(--maincolord2hover);
    color: var(--maincolord2hover);
}

.ex-t-o-login {
    border: 2px solid var(--maincolord2);
    background-color: var(--maincolord2);
    color: #fff;
}

.ex-t-o-login:hover {
    border-color: var(--maincolord2hover);
    background-color: var(--maincolord2hover);
}


@media screen and (max-width: 1300px) and (min-width: 768px) {
    .ex-trading {
        height: unset !important;
    }

    .ex-tading-bottom {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: end;
        flex-grow: 0;
        row-gap: 1.5rem;
    }

    .ex-tading-bottom>* {
        width: calc(50% - .5rem);
        order: 3;
        min-height: 2.8rem;
    }

    .ex-t-balance {
        order: 2;
    }

    .ex-t-amount {
        order: 1;
    }

    .ex-t-amount input,
    .ex-t-number input {
        height: 2.8rem;
    }

    .ex-t-radio {
        margin: 0;
    }
}

/* //////////////////////////////////////////////////// ex-orders */
.ex-orders {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    overflow: hidden;
}

.ex-orders-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff99;
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.ex-orders-overlay img {
    max-width: 50vw;
}

.ex-o-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ex-o-top-left {
    display: flex;
}

.ex-o-top-left span {
    padding: .5rem 1rem;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.ex-o-top-left span:hover {
    border-color: #ddd;
}

.ex-o-top-left span.active {
    color: var(--maincolor2) !important;
    border-color: var(--maincolor2);
    pointer-events: none;
    font-weight: bold;
}

.ex-o-top-right {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
}

.ex-o-toggle input {
    display: none;
}

.ex-o-toggle label {
    position: relative;
    cursor: pointer;
    display: block;
    width: 40px;
    height: 24px;
    background: #cccccc;
    border-radius: 50px;
    transition: .2s;
}

.ex-o-toggle label:before {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 50%;
    transition: left .2s;
}

.ex-o-toggle input:checked+label {
    background: var(--maincolor2);
}

.ex-o-toggle input:checked+label:before {
    left: 18px;
}

.ex-o-middle,
.ex-o-bottom-row {
    display: grid;
    gap: 5px;
    grid-template-columns: 1.5fr 1.5fr 1.8fr 2fr 2.2fr 1.2fr 1fr 2fr 1.5fr;
    align-items: center;
}

.ex-o-middle {
    padding: 0 5px;
}

.ex-o-middle>div {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    width: fit-content;
}

.ex-o-middle>div:hover span {
    color: #000;
}

.ex-o-middle>div:last-child {
    pointer-events: none;
}

.ex-o-middle>div span {
    font-size: 13px;
    color: #aaa;
    display: contents;
}

.ex-o-middle>div img {
    width: 8px;
}

.ex-o-bottom {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
    overflow-y: auto;
}

.ex-o-bottom-row {
    background-color: #F6F7F9;
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
}

.ex-o-b-r-title {
    display: none;
}

.ex-o-bottom-row .irr {
    color: #ccc;
}

.ex-o-bottom-row button {
    color: var(--maincolor3);
    /* background-color: var(--maincolor3light); */
    font-weight: bold;
    width: fit-content;
    /* padding: 4px 13px; */
    border-radius: 5px;
    cursor: default;
}

.ex-o-bottom-row>div:last-child button {
    padding: 7px 13px;
    background-color: #ffe2e2;
    color: red;
    font-weight: normal;
    cursor: pointer;
}

.ex-o-bottom-row>div:last-child button:hover {
    background-color: #ffd8d8;
}

.ex-o-bottom-row>div:last-child button:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.ex-o-bottom-row>div:first-child span:last-child {
    padding: 0 10px;
}

.ex-o-b-close {
    color: var(--maincolorred);
    display: flex;
    gap: 3px;
    align-items: center;
    cursor: pointer;
}

.ex-o-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.ex-o-empty img {
    width: 170px;
}


@media screen and (max-width: 1300px) {
    .ex-orders {
        height: unset !important;
        min-height: 28rem;
        max-height: 40rem;
    }
}

@media screen and (max-width: 768px) {
    .ex-orders {
        max-height: 50rem;
    }

    .ex-o-top {
        flex-direction: column;
        gap: 1rem;
    }

    .ex-o-top>* {
        width: 100%;
    }

    .ex-o-top-left>* {
        flex-grow: 1;
        text-align: center;
    }

    .ex-o-top-right {
        justify-content: end;
    }

    .ex-o-middle {
        display: none;
    }

    .ex-o-bottom-row {
        grid-template-columns: 1.2fr 1.4fr 1fr;
        padding: 1rem;
        row-gap: 1rem;
        align-items: start;
    }

    .ex-o-b-r-title {
        display: block;
        color: #aaa;
        margin-bottom: 2px;
        font-size: 13px;
    }

    .ex-o-b-close {
        align-self: center;
    }

    .ex-o-bottom-row>div:first-child span:last-child {
        padding: 0;
    }
}


/* ////////////////////////////////////////////// .ex-volumes */
.ex-volumes {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ex-v-top {
    display: flex;
}

.ex-v-top button {
    /* flex-grow: 1; */
    padding: .5rem 1rem;
    font-size: 16px;
    border-bottom: 1px solid #eee;
    height: 2.5rem;
    transition: .1s;
}

.ex-v-top button:hover {
    border-color: #ddd;
}

.ex-v-top button.active {
    font-weight: bold;
    color: var(--maincolor2) !important;
    border-color: var(--maincolor2);
    pointer-events: none;
}

.ex-v-bottom {
    height: calc(100% - 3.5rem);
}

.ex-v-bottom-top,
.ex-v-b-b-row {
    display: flex;
    justify-content: space-between;
}

.ex-v-bottom-top {
    margin-bottom: 5px;
    height: 20px;
    padding-right: 10px;
}

.ex-v-bottom-top span {
    color: #aaa;
    font-size: 13px;
}

.ex-v-bottom-bottom {
    display: flex;
    flex-direction: column;
    gap: 1px;
    background-color: #eee;
    overflow-y: auto;
    max-height: calc(100% - 25px);
}

.ex-v-b-b-row {
    font-size: 15px;
    background-color: #fff;
    padding: 3px 0;
    padding-right: 7px;
}

.ex-v-b-b-row:nth-child(odd) span:first-child {
    color: var(--maincolor3);
}

.ex-v-b-b-row:nth-child(even) span:first-child {
    color: #EB5353;
}

.ex-v-b-b-row span:last-child {
    color: #555;
}

.ex-v-v-top {
    height: 50%;
}

.ex-v-v-sellbuy {
    display: flex;
    justify-content: space-between;
    height: 2.5rem;
    position: relative;
}

.ex-v-v-sellbuy i {
    position: absolute;
    bottom: 10px;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 999px;
    background-color: var(--maincolor3);
}

.ex-v-v-sellbuy i::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: inherit;
    width: var(--percent);
    background-color: #EB5353;
}

.ex-v-v-sell,
.ex-v-v-buy {
    font-weight: bold;
    font-size: 15px;
}

.ex-v-v-sell {
    color: #D21A1A;
}

.ex-v-v-buy {
    color: var(--maincolor3);
}

.ex-v-v-t-bottom {
    height: calc(100% - 2.5rem);
}

.ex-v-v-t-b-top {
    height: 2rem;
    color: #aaa;
    font-size: 13px;
    display: grid;
    grid-template-columns: 2fr 2fr 1.2fr;
}

.ex-v-v-volumes {
    height: calc(100% - 2rem);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.ex-v-v-volumes-row {
    border-radius: 3px;
    font-size: 14px;
    padding: 2px 10px;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
}

.ex-v-v-t-bottom .ex-v-v-volumes-row {
    color: #EB5353;
    position: relative;
}

.ex-v-v-t-bottom .ex-v-v-volumes-row::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: var(--percent);
    background-color: #FDEDED;
    z-index: 0;
    border-radius: inherit;
}

.ar .ex-v-v-t-bottom .ex-v-v-volumes-row::before {
    left: unset;
    right: 0;
}

.ex-v-v-volumes-row span {
    z-index: 1;
}

.ex-v-v-bottom {
    height: 50%;
}

.ex-v-v-b-top {
    height: 2rem;
    display: flex;
    gap: 2px;
    font-size: 14px;
}

.ex-v-v-b-top span {
    font-weight: bold;
    color: var(--maincolor3);
    font-size: 17px;
}

.ex-v-v-b-top svg {
    fill: var(--maincolor3);
}

.ex-v-v-volumes {
    height: calc(100% - 2.5rem);
}

.ex-v-v-bottom .ex-v-v-volumes-row {
    position: relative;
    color: #076400;
}

.ex-v-v-bottom .ex-v-v-volumes-row::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: var(--percent);
    background-color: #EEFDED;
    z-index: 0;
    border-radius: inherit;
}

.ar .ex-v-v-bottom .ex-v-v-volumes-row::before {
    left: unset;
    right: 0;
}


@media screen and (max-width: 1300px) and (min-width: 768px) {
    .ex-volumes {
        max-height: 25rem;
    }

    .ex-v-bottom-2 {
        display: flex;
        gap: 1rem;
        align-items: end;
    }

    .ex-v-bottom-2>* {
        height: 100%;
        width: 50%;
    }

    .ex-t-number span:last-child {
        bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .ex-v-top button {
        flex-grow: 1;
    }
}


/* ///////////////////////////////////////////////////// DARK THEME */
#dark .ex-container,
#dark .ex-top,
#dark .ex-bottom>div,
#dark .coin-selectbox-option,
#dark .ex-v-b-b-row,
#dark .ex-top-three>div {
    background-color: #000;
}

#dark .coin-selectbox {
    background-color: #555;
    border-color: #777;
    box-shadow: 0 1px 10px #ffffff53;
}

#dark .ex-top,
#dark .ex-bottom>div {
    box-shadow: 0 1px 10px #ffffff53;
}

#dark .ex-select-coin-top,
#dark .ex-top-two-num,
#dark .ex-trading-top-left,
#dark .ex-o-top-left span,
#dark .ex-v-top button,
#dark .ex-t-total span,
#dark .ex-v-b-b-row span:nth-child(2) {
    color: #fff;
}

#dark .ex-v-b-b-row span:last-child {
    color: #999;
}

#dark .ex-top-three svg,
#dark .ex-select-arrow svg {
    fill: #fff;
}

#dark .coin-selectbox-option:hover {
    background-color: #222;
}

#dark .ex-o-middle>div img {
    filter: invert(1);
}

#dark .ex-v-v-t-bottom .ex-v-v-volumes-row::before {
    background-color: #2c0000;
}

#dark .ex-v-v-bottom .ex-v-v-volumes-row::before {
    background-color: #022000;
}

#dark .ex-v-v-bottom .ex-v-v-volumes-row {
    color: #0ed400;
}

#dark .ex-chart-top button.active {
    background-color: var(--maincolor2lightDarkTheme);
}

#dark .ex-trading-top-right button:first-child:hover {
    background-color: #500000;
}

#dark .ex-trading-top-right button:last-child:hover {
    background-color: #002e2b;
}

#dark .ex-t-amount input,
#dark .ex-t-number input,
#dark .ex-t-balance {
    background-color: #000;
    color: #fff;
}

#dark .ex-t-amount input:disabled {
    opacity: 0.5;
}

#dark .ex-t-balance {
    background-color: #222;
    border-color: #777;
}

#dark .coin-selectbox-option:last-child img {
    filter: invert(1);
}

#dark .ex-trading-overlay {
    background-color: #00000088;
    color: #fff;
}

#dark .ex-t-total {
    border-color: #555;
}

#dark .ex-trading-middle span:hover {
    color: #ccc;
}

#dark .ex-o-middle>div span:hover {
    color: #ccc;
}

#dark .ex-o-bottom-row {
    background-color: #181818;
    color: #fff;
}

#dark .ex-o-bottom-row>div:last-child button {
    background-color: #470000;
}

#dark .ex-o-bottom-row>div:last-child button:hover {
    background-color: #570000;
}

#dark .ex-o-empty {
    color: #fff;
}


@media screen and (max-width: 768px) {
    #dark .ex-top {
        box-shadow: none;
    }

    #dark .ex-top-coin {
        background-color: #222;
    }
}