#wallet-rial{
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: #f7f8fa;
    /* border: 1px solid green; */
}
#wallet-rial>main{
    
    min-height: 100vh;
    padding: 10px 30px;
    /* border: 1px solid black; */
}

/* واریز-برداشت *//* واریز-برداشت */
/* واریز-برداشت *//* واریز-برداشت */
#wallet-rial>main .deposit_withdraw{
    width: 100%;
    height: 660px;
    
    border-radius: 20px;
    padding: 15px;
    /* border: 1px solid purple; */
}
#wallet-rial>main .deposit_withdraw .two_button>button{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 255px;
   height: 46px;
   border: 1px solid #d1d1d1;
   border-radius: 10px;
}
/* ////////////////////باکس واریز////////////*/
/* //////////////////// باکس واریز////////////*/

#wallet-rial .deposit-box{
    border: 1px solid #edeef1;
    /* border: 1px solid blue; */
    box-shadow: 1px 1px 5px rgb(207, 204, 204);
    padding: 15px 15px;
}
#wallet-rial .deposit-box>div{
    /* border: 1px solid black; */
}
#wallet-rial .deposit-box .parent_networks{
    /* border: 1px solid black; */
}
#wallet-rial .ballet{
    display: block;
    width: 20px;height: 20px;
    border-radius: 50%;
    border:2px solid #edeef1;
}
/* /////////////////باکس برداشت////////////////// */
/* /////////////////باکس برداشت////////////////// */
#wallet-rial .withdraw-box{
    border: 1px solid #edeef1;
    /* border: 1px solid blue; */
    box-shadow: 1px 1px 5px rgb(207, 204, 204);
    padding: 15px 15px;
}
#wallet-rial .withdraw-box>div{
    /* border: 1px solid black; */
}

/* //////////////ریسپانسیو ///////////////// */
/* //////////////ریسپانسیو ///////////////// */

/* max 640     mobile-mobile */
@media screen and (max-width:640px) {
    #wallet-rial>main{
    width: 100%;
    padding: 10px 15px;
    }
}

/* min 640 sm-sm-sm-sm-sm*/
@media screen and (min-width:640px) {
    #wallet-rial>main{
        /* background: rgb(217, 186, 127); */
        width: 100%;
        padding: 10px 15px;
        }
}
/* min 768 md-md-md-md-md*/
@media screen and (min-width:768px) {
    
}
/* min 1024 lg-lg-lg-lg-lg*/
@media screen and (min-width:1024px) {
    #wallet-rial>main{
        width: 82%;
        padding: 10px 15px;
        }
}
/* min 1280   xl-xl-xl-xl*/
@media screen and (min-width:1280px) {
}
/* min 1536   2xl-2xl-2xl-2xl*/
@media screen and (min-width:1536px) {
   
}