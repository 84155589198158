/* //////////// grand-parent ///////////////////*/
#light .grand-parent{
    background:white ;
}
#dark .grand-parent{
     background:black;
}
/* //////////// login-right ///////////////////*/
#light .login-right{
    background: white ;
}
#dark .login-right{
    background: black ;
}
/* //////////////// h1 ///////////////////////*/
#light .login-right h1{
    color: black;
}
#dark .login-right h1{
   color: white;
}

/* ////////////eye ////////////////*/
#light .login-right form>div span.eye{
    
    color:black ;
}
#dark .login-right form>div span.eye{
 
    color:white ;
}

/* //////////////section label //////////////// */
#light .login-right form>section label{
    color: var(--light-gray-1);
}
#dark .login-right form>section label{
    color: var(--dark-gray-1);
}
/* //////////////submit button///////////// */
#light .login-right form .submit-form button{
    background: var(--light-yellow-1);
    color: white;
}
#dark .login-right form .submit-form button{
    background: var(--dark-yellow-1);
    color: white;
}
/* //////////// */
#light .login-right form > .recently span{
    color: var(--dark-gray-2);
}
#dark .login-right form > .recently span{
    color: var(--light-gray-2);
}