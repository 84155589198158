/* ///////////////////////////////////////////// */
#light #wallet{
    background: var(--light-gray-4);
}
#dark #wallet{
    background: var(--dark-gray-4);
}
/* ///////////////////////////////////////////// */
#light #wallet .wallet_userData{
    background: white;
}
#dark #wallet .wallet_userData{
    background: black;
}
/* ///////////////////////////////////////////// */
#light #wallet .wallet_userData>ul{
    color: black;
}
#dark #wallet .wallet_userData>ul{
    color: white;
}
/* ///////////////////////////////////////////// */
#light #wallet .digital-wallet{
    background: white;
}
#dark #wallet .digital-wallet{
    background: black;
}
/* ///////////////////////////////////////////// */
#light #wallet .digital-wallet>div:nth-of-type(1) button{
    border: 1px solid var(--light-gray-3);
    color: var(--light-gray-1);
}
#dark #wallet .digital-wallet>div:nth-of-type(1) button{
    border: 1px solid var(--dark-gray-3);
    color: var(--dark-gray-1);
}
/* ///////////////// thead //////////////////////////// */
#light #wallet .digital-wallet thead tr{
    color: var(--dark-gray-2);
}
#dark #wallet .digital-wallet thead tr{
    color: var(--light-gray-2);
}
/* ///////////////// tbody //////////////////////////// */
#light #wallet .digital-wallet tbody tr{
    color: black;
}
#dark #wallet .digital-wallet tbody tr{
    color: white;
}
/* //////////////// filter //////////////////////*/
#light #wallet .filter_parent>section{
    background: white;
    box-shadow: 1px 1px 5px rgb(126, 122, 122);
}
#dark #wallet .filter_parent>section{
    background: black;
    box-shadow: 1px 1px 5px rgb(38, 37, 37);
}
/* ///////////////////////////////////////////// */
#light #wallet .filter_parent>section>div:nth-of-type(1) button{
    background: var(--light-gray-4);
    color: black;
}
#dark #wallet .filter_parent>section>div:nth-of-type(1) button{
    background: var(--dark-gray-4);
    color: white;
}
/* ///////////////////////////////////////////// */
#light #wallet .filter_parent>section label{
    color: var(--dark-gray-2);
}
#dark #wallet .filter_parent>section label{
    color: var(--light-gray-2);
}
/* ///////////////////////////////////////////// */
#light #wallet .filter_parent>section select{
    background: var(--light-gray-4);
    color: var(--light-gray-1);
}
#dark #wallet .filter_parent>section select{
    background: var(--dark-gray-4);
    color: var(--dark-gray-1);
}