/* page404-section-one */
#page404-section-one {
  padding: 4rem 0;
}
#page404-section-one .all {
  width: 65%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#dark #page404-section-one .all {
  color: #fff;
}
#page404-section-one .all .des {
  padding: 1rem 0;
}
#page404-section-one .all img {
  width: 50%;
}
#page404-section-one .all .mobile {
  display: none;
}
#page404-section-one .all .title {
  font-size: 27px;
}
#page404-section-one .all a {
  color: var(--maincolor2);
  padding: 12px 68px;
  border: 1px solid var(--maincolor2);
  border-radius: 10px;
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  #page404-section-one .all {
    width: 85%;
  }
  #page404-section-one .all img {
    width: 100%;
  }
  #page404-section-one .all {
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  #page404-section-one .all {
    width: 85%;
  }
  #page404-section-one .all img {
    width: 100%;
  }
  #page404-section-one .all {
    text-align: center;
  }
  #page404-section-one .all img {
    display: none;
  }
  #page404-section-one .all .mobile {
    display: block;
  }
}
