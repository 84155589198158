/* module */
.module-1 {
  width: 100%;
  height: 100%;
  background: #0000001f;
  backdrop-filter: blur(7px);
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: show-up .3s forwards;
}
@keyframes show-up {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.module-1 .all {
  width: 636px;
  height: 327px;
  background: #fff;
  padding: 22px;
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.module-1 .all .top {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.module-1 .all .top .right {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.module-1 .all .top .left {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--maincolorborder2);
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
}
.module-1 .all .top .right div {
  padding: 0 10px;
}
.module-1 .all .top .left div {
  padding: 0 7px;
}
.module-1 .all .all-grid {
  display: grid;
  grid-template-columns: auto auto;
}
.module-1 .all .all-grid .grid-item {
  padding: 1rem;
}
.module-1 .all .all-grid .grid-item .carts input {
  margin-top: 10px;
  border: 1px solid var(--maincolorborder2);
  height: 46px;
  width: 100%;
  border-radius: 10px;
}
.module-1 .add-cart {
  width: 100%;
  height: 46px;
  background: #f9cc0b;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
#everythings {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}
#everythings #all {
  width: 82%;
  padding: 10px 30px;
  background: #f7f8fa;
  position: relative;
  padding-bottom: 5rem;
}
#dark #everythings #all {
  background: #141414;
}
#everythings #all .top {
  display: flex;
  justify-content: space-between;
  font-family: Shabnam;
}
#dark #everythings #all .top {
  color: #fff;
  background: #000;
}
#everythings #all .top .tabs .MuiBox-root.css-1gsv261 {
  width: fit-content;
  border: 0;
}
#everythings #all .top .tabs button {
  width: fit-content;
  font-family: Shabnam;
}
#everythings #all .top .tabs span {
  display: flex;
  align-items: center;
}
#everythings #all .top .tabs span img {
  margin: 0 5px;
}
#everythings #all .top .tabs .Mui-selected {
  width: fit-content;
  background: #f9cc0b;
  border-radius: 10px;
  color: #fff;
  font-family: Shabnam-Bold;
}
#everythings #all .top .tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  width: fit-content;
  border-radius: 10px;
  margin: 0 5px;
  border: 1px solid #edeef1;
}
#dark #everythings #all .top .tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #fff;
}
#everythings #all .top .tabs .css-1aquho2-MuiTabs-indicator {
  display: none;
}
#dark #everythings #all .body  {
    background: #000;
}
#everythings #all .body #information .first-inputs {
  font-family: shabnam-light;
  display: grid;
  grid-template-columns: auto auto auto;
}
#everythings #all .body #information .first-inputs .name {
  font-family: shabnam-light;
  font-size: 14px;
  color: #707070;
  padding: 0 1rem;
}
#everythings #all .body #information .input-box {
  margin-top: 1rem;
}
#everythings #all .body #information .first-inputs .input-box {
  height: 46px;
  justify-content: space-between;
  display: flex;
  border: 1px solid #edeef1;
  border-radius: 10px;
  padding: 0 5px;
}
#dark #everythings #all .body #information .first-inputs .input-box {
  background: #000;
}
#everythings #all .body #information .first-inputs .input-box input {
  width: 100%;
  height: 43px;
}
#dark #everythings #all .body #information .first-inputs .input-box input {
  background: #000;
}
#everythings #all .body #information .first-inputs .input-box input:focus {
  outline: 0;
}
#everythings #all .body #information .first-inputs .code-meli {
  font-family: shabnam-light;
  font-size: 14px;
  color: #707070;
  padding: 0 1rem;
}
#everythings #all .body #information .first-inputs .tarikh-tvl {
  padding: 0 1rem;
}
#everythings #all .body #information .first-inputs .tarikh-tvl label {
  font-family: shabnam-light;
  font-size: 14px;
  color: #707070;
}

#everythings #all .body #information .first-inputs .tarikh-tvl .rmdp-input {
  border: 0;
}

#everythings #all .body table {
  width: 100%;
}
#dark #everythings #all .body table {
  color: #fff;
}
#everythings #all .body table th {
  font-family: Shabnam-Light-FD;
  font-size: 12px;
  color: #86919e;
}
#everythings #all .body table td {
  padding: 1rem 0;
  border-bottom: 1px solid #edeef1;
  font-family: shabnam-fd;
}
#everythings #all .body table td .box {
  background: #d8f3f1;
  width: 98px;
  height: 35px;
  color: #00c0af;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#everythings #all .body table td:last-child {
  cursor: pointer;
}
#everythings .add-account {
  width: 330px;
  height: 46px;
  background: #f9cc0b;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 4rem;
  left: 2rem;
}
#everythings .add-account .text {
  padding-right: 15px;
}
#everythings .a-h {
  display: grid;
  grid-template-columns: auto auto auto;
}
#everythings .a-h .grid-item {
  padding: 0 5px;
}
#everythings .a-h .grid-item .carts {
  border: 1px solid #edeef1;
  border-radius: 20px;
  font-family: shabnam;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 352px;
}
#everythings .a-h .grid-item .carts .title {
  font-family: shabnam-bold;
}
#everythings .a-h .grid-item .carts .des {
  font-family: shabnam-light;
  font-size: 14px;
  color: #8b8b8b;
  overflow-x: hidden !important;
}
.body:nth-child(2) .MuiBox-root p {
  overflow-x: hidden !important;
}
#everythings .a-h .grid-item .carts .state {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-radius: 10px;
  font-family: shabnam-bold;
}
#dark #everythings .a-h .grid-item .carts .state {
  color: #fff;
}
#everythings .add-account {
  font-family: shabnam-bold;
}
/* security */
#everythings .security {
  padding: 2rem 0;
}
#everythings .security .change-password {
  padding: 2rem 0;
}
#everythings .security .change-password .title {
  font-family: shabnam-bold;
  font-size: 18px;
}
#everythings .security .change-password .grids {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
}
#everythings .security .change-password .grids .grid-items {
  padding: 0 1rem;
}
#everything-r#all .body td, th{
  text-align: left;
}
.ar #everything-r#all .body td, th{
  text-align: right;
}
#everythings .security .change-password .grids .grid-items .carts label {
  font-family: shabnam-light;
  font-size: 14px;
  color: #707070;
}
#everythings .security .change-password .grids .grid-items .carts .input-eyes {
  margin-top: 5px;
  width: 100%;
  height: 46px;
  border: 1px solid #edeef1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
}
#everythings .security .change-password .grids .grid-items .carts input {
  padding: 7px;
}
#dark #everythings .security .change-password .grids .grid-items .carts input {
  background: #000;
}
#everythings .security .verify .title {
  font-family: Shabnam-Bold-FD;
  font-size: 18px;
  width: 100%;
}
#everythings .security .verify .lr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#everythings .security .verify .right {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-top: 25px;
}
#everythings .security .verify .right .text1 {
  color: #707070;
  font-family: shabnam-light;
  font-size: 14px;
  text-align: right;
  padding-bottom: 15px;
}
#everythings .security .verify .right .text2 {
  color: #707070;
  font-family: shabnam-light;
  font-size: 14px;
  text-align: right;
  padding-bottom: 15px;
}
#everythings .security .verify .right .code {
  width: 100%;
  height: 46px;
  border: 1px solid #edeef1;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}
#everythings .security .verify .right .code img {
  cursor: pointer;
  margin-right: 15px;
}
#everythings .security .verify .right .google-auth {
  display: flex;
  justify-content: space-between;
}
#everythings .security .verify .right .google-auth input {
  width: 63%;
  border: 1px solid #edeef1;
  height: 46px;
  border-radius: 10px;
}
#dark #everythings .security .verify .right .google-auth input {
  background: #000;
}
#everythings .security .verify .right .google-auth button {
  width: 35%;
  height: 46px;
  border-radius: 10px;
  background: #f9cc0b;
  color: #fff;
  font-family: shabnam-light;
}
#everythings .security .verify .left {
  width: 49%;
  display: flex;
  justify-content: space-evenly;
}
#everythings .security .verify .left .text {
  font-size: 14px;
  font-family: shabnam-light;
  color: #707070;
}
/* #everythings .notifications {
  width: 100%;
  height: 100%;
  padding: 2rem;
} */
#everythings .notifications .divs .pile {
  padding: 1rem 0;
  border-bottom: 1px solid #edeef1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: shabnam-light-fd;
}
#dark #everythings .notifications .divs .pile {
  color: #fff;
}
#everythings .setting {
  width: 100%;
  width: 100%;
  padding: 2rem;
}
#everythings .setting .all {
  display: grid;
  grid-template-columns: auto auto auto;
}
#everythings .setting .all .grid-items {
  padding: 0 0.5rem;
}
#everythings .setting .all .grid-items .carts {
  font-family: shabnam;
  width: 100%;
}
#everythings .setting .all .grid-items .carts .title {
  font-family: shabnam-light;
  font-size: 14px;
  color: #707070;
  padding-bottom: 15px;
}
#everythings .setting .all .grid-items .carts select {
  width: 100%;
  height: 46px;
  border: 1px solid #edeef1;
  border-radius: 10px;
}
#dark #everythings .setting .all .grid-items .carts select {
  color: #fff;
}
#dark #everythings .setting .all .grid-items .carts select {
  background: #000;
}
#everythings .setting .all .grid-items .carts .ini {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#everythings .setting .all .grid-items .carts .text {
  display: flex;
}
#dark #everythings .setting .all .grid-items .carts .text {
  color: #fff;
}
/* responsive */
@media only screen and (max-width: 1024px) {
  .module-1 .all {
    width: 80%;
    height: 500px;
  }
  .module-1 .all .all-grid {
    display: grid;
    grid-template-columns: auto;
  }
  #everythings #all {
    padding: 1rem;
  }
  #everythings .security .change-password .grids {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
  }
  #everythings .security .change-password .grids .grid-items {
    padding: 1rem;
  }
  #everythings .a-h .grid-item {
    padding: 5px;
  }
  #everythings #all {
    width: 100%;
  }
  #everythings #all .body #information .first-inputs .tarikh-tvl {
    padding: 1rem 1rem;
  }
  #everythings .MuiBox-root p {
    overflow-x: unset !important;
  }
  #everythings #all .body #information .first-inputs {
    grid-template-columns: auto auto;
  }
  #everythings .a-h {
    grid-template-columns: auto auto;
  }
  #everythings .setting .all {
    grid-template-columns: auto auto;
  }
  #everythings .setting .all .grid-items .carts {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 640px) {
  #everythings .add-account {
    width: 100%;
    position: unset;
    margin-bottom: -4px;
  }
  #everythings #all .body #information .first-inputs {
    grid-template-columns: auto;
  }
  #everythings #all .body #information .first-inputs .code-meli {
    padding: 1rem 1rem;
  }
  #everythings .a-h {
    grid-template-columns: auto;
  }
  #everythings .security .change-password .grids {
    grid-template-columns: auto;
  }
  #everythings .security .change-password .grids .grid-items {
    padding: 1rem 0;
  }
  #everythings .security .verify .lr {
    flex-direction: column;
  }
  #everythings .security .verify .right {
    width: 100%;
  }
  #everythings .security .verify .left {
    width: 100%;
    margin-top: 25px;
  }
  #everythings .setting .all {
    grid-template-columns: auto;
  }
}


.setting .body {
  background-color: #fff;
  border-radius: 20px;
  overflow-x: auto;
}
.setting .top {
  background-color: #fff;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
}