#welcome img {
    max-width: unset !important;
}

#welcome {
    direction: ltr;
    font-family: Shabnam;
    color: #fff;
    text-align: center;
    font-weight: bold;
    overflow-x: hidden;
    background-image: url(images/welcome_bg31.png);
    background-repeat: no-repeat;
    background-size: contain;
}

#welcome .smartsupp-widget-container {
    display: none;
}

@media screen and (max-width: 1024px) {
    #welcome {
        background-size: auto;
    }
}

#welcome #mojbit,
#welcome #five {
    animation: textAnim 1s infinite;
}

@keyframes textAnim {
    0% {
        font-weight: normal;
    }

    100% {
        font-weight: bold;
    }
}

#welcome #calendar {
    background-image: url(images/calendar4.png);
    background-size: cover;
}

#welcome #start_btn {
    animation: startBtn 1.5s infinite;
}

#welcome #start_btn:hover {
    transform: scaleX(1.05) !important;
    animation-play-state: paused;
}

#welcome #start_btn:active {
    transform: scaleX(1.05) translateY(3px) !important;
    box-shadow: 0 0px 0 #f6b33f7c !important;
}

@keyframes startBtn {

    0%,
    20%,
    80%,
    100% {
        transform: scaleX(1);
    }

    50% {
        transform: scaleX(1.05);
    }
}

#welcome .row i {
    display: block;
    width: 10px;
    min-width: 10px;
    height: 2px;
    border-radius: 999px;
    background-color: #fff;
    position: relative;
}

#welcome .row i::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    left: 0;
    background-color: inherit;
    transform: rotate(90deg) scaleX(1);
    transition: .3s;
}

#welcome .row i.plusAnim::before {
    transform: rotate(90deg) scaleX(0);
}

#welcome #start_btn2:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 0 #f6b33f7c !important;
}

#welcome #start_btn2:active {
    transform: translateY(3px);
    box-shadow: 0 0px 0 #f6b33f7c !important;
}

#welcome h2 {
    line-height: 1.6 !important;
}