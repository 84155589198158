#everything-r {
  display: flex;
}
#everything-r #all {
  width: 82%;
  padding: 2rem;
  position: relative;
}
#everything-r #all .top .tabs .MuiBox-root.css-1gsv261 {
  width: fit-content;
}
#everything-r #all .top .tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  width: 132px;
  font-family: Shabnam;
}
#dark
  #everything-r
  #all
  .top
  .tabs
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #fff;
}
#everything-r
  #all
  .top
  .tabs
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  width: 132px;
  color: #f9cc0b;
  font-family: Shabnam-Bold;
}
#dark
  #everything-r
  #all
  .top
  .tabs
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #f9cc0b;
}
#all .top .tabs .css-1aquho2-MuiTabs-indicator {
  background: #f9cc0b;
}

#everything-r #all table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#everything-r#all .body td,
th {
  border-bottom: 1px solid #edeef1;
  /* text-align: left; */
  padding: 8px;
  /* text-align: right; */
  font-family: Shabnam-FD;
}
#dark #everything-r #all .body td {
  color: #fff;
}
#all .body  p  {
  overflow-x: auto;
  width: 100%;
}
#dark #everything-rg #all .body th {
  color: #fff;
}
#everything-r #all .body th .list {
  display: flex;
  align-items: center;
  font-family: Shabnam-Light-FD;
  font-size: 12px !important;
  color: #86919e;
}
#everything-r #all .body th .list img {
  padding-right: 15px;
  cursor: pointer;
  width: 23px;
}
