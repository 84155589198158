#helpcenter-section-one{
    padding: 2rem 0;
    padding-top: 6rem;
}
#helpcenter-section-one .all{
    width: 40%;
    margin: auto;
    position: relative;
    padding: 2rem;
}
#helpcenter-section-one .all .title{
    font-family: Poppins-Bold;
    font-size: 27px;
    text-align: center;
}
.ar #helpcenter-section-one .all .title{
    font-family: shabnam-Bold;
}
#dark #helpcenter-section-one .all .title{
    color: #fff;
}
#helpcenter-section-one .all .des{
    font-family: Shabnam-FD;
    font-size: 16px;
    text-align: center;
}
#dark #helpcenter-section-one .all .des{
    color: #fff;
}
#helpcenter-section-one .all .design-img-1{
    width: 32.62px;
    height: 52.69px;
    position: absolute;
    top: 0;
    left: 70px;
}
#helpcenter-section-one .all .design-img-2{
    width: 32.62px;
    height: 52.69px;
    position: absolute;
    top: 20px;
    right: 10px;
    transform: rotate(20deg);
}
#helpcenter-section-one .all .design-img-1 img{
    width: 100%;
    height: 100%;
}
#helpcenter-section-one .all .design-img-2 img{
    width: 100%;
    height: 100%;
}
#helpcenter-section-one .all form{
    width: 100%;
    height: 80px;
    box-shadow: 0 10px 50px #00000020;
    display: flex;
    align-items: center;
}
#dark #helpcenter-section-one .all form{
    background: #000;
    box-shadow: 0 10px 50px #ffffff20;
    border-radius: 20px;
    padding: 7px 15px;
}
#helpcenter-section-one .all form input{
    height: 55px;
    width: 90%;
    border: 0;
    margin-left: 3px;
}
#helpcenter-section-one .all form .search-icon{
    width: 55px;
    height: 55px;
    background: var(--maincolor3);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* section-two */
#helpcenter-section-two{
    padding: 2rem 0;
}
#helpcenter-section-two .all{
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns:  auto auto;
}
#helpcenter-section-two .all .grid-item{
    padding: 0 1rem;
}
#helpcenter-section-two .all .grid-item .carts{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border: 1px solid #F2EEDA;
    padding: 1rem;
    border-radius: 20px;
}
#helpcenter-section-two .all .grid-item .carts img{
    margin-top: -70px;
}
#helpcenter-section-two .all .grid-item .carts .title{
    font-family: Poppins-Bold;
}
.ar #helpcenter-section-two .all .grid-item .carts .title{
    font-family: shabnam-Bold;
}
#dark #helpcenter-section-two .all .grid-item .carts .title{
    color: #fff;
}
#helpcenter-section-two .all .grid-item .carts .des{
    font-family: Poppins-Regular;
    font-size: 14px;
}
.ar #helpcenter-section-two .all .grid-item .carts .des{
    font-family: shabnam-light;
}
#dark #helpcenter-section-two .all .grid-item .carts .des{
    color: #fff;
}
/* section-two */
#helpcenter-section-three{
    padding: 1rem 0;
}
#helpcenter-section-three .all{
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns:  auto auto auto auto; 
}
#helpcenter-section-three .all .grid-item{
    padding: 1rem;
}
#helpcenter-section-three .all .grid-item .carts{
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #F2EEDA;
    padding: 2rem;
    border-radius: 20px;
    max-width: 273px;
}
#helpcenter-section-three .all .grid-item .carts .title{
    padding-top: 19.5px;
}
#dark #helpcenter-section-three .all .grid-item .carts .title{
    color: #fff;
}



/* responsive */
@media only screen and (max-width: 1400px) {
    #helpcenter-section-three .all .grid-item{
        padding: 1rem;
        text-align: center;
    }
}
@media only screen and (max-width: 1200px) {
    #helpcenter-section-two .all{
        width: 90%;
    }
    #helpcenter-section-three .all{
        width: 90%;
    }
    #helpcenter-section-one .all{
        width: 60%;
    }
}
@media only screen and (max-width: 992px) {
    #helpcenter-section-two .all .grid-item{
        padding: 1rem;
    }
    #helpcenter-section-one .all{
        width: 90%;
    }
    #helpcenter-section-three .all{
        grid-template-columns:  auto auto; 
    }
    #helpcenter-section-three .all .grid-item .carts .title{
        width: 157px;
    }
}

@media only screen and (max-width: 768px) {
    #helpcenter-section-one .all{
        width: 100%;
    }
    #helpcenter-section-two .all {
        grid-template-columns:  auto; 
    }
    #helpcenter-section-two .all .grid-item .carts{
       margin-bottom: 50px;
    }
    #helpcenter-section-two{
        padding: 0;
    }
    #helpcenter-section-one .all .design-img-1{
        width: 32.62px;
        height: 52.69px;
        position: absolute;
        top: 0;
        left: 30px;
        z-index: -1;
    }
    #helpcenter-section-three .all{
        grid-template-columns:  auto ; 
    }
    #helpcenter-section-three .all .grid-item .carts{
        max-width: unset;
    }
}