#invite {
    position: relative;
    width: 100%;
    min-height: 100vh;
    user-select: none;
    /* border: 2px solid purple; */
}

/* /////////////////// main ///////////////////////// */
/* /////////////////// main ///////////////////////// */
#invite >main {
    min-height: 100vh;
    /* border: 1px solid red; */
}

/* //////////////////responsive ریسپانسیو///////////////////*/
/* //////////////////responsive ریسپانسیو///////////////////*/
/* //////////////////responsive ریسپانسیو///////////////////*/


/* max 640     mobile-mobile */
@media screen and (max-width:640px) {
    

    #invite >main {
        width: 100%;
        padding: 10px 15px;
        

    }

  

}

/* min 640 sm-sm-sm-sm-sm*/
@media screen and (min-width:640px) {
    #invite {
        /* background: rgb(193, 193, 142); */
    }

   


    #invite >main {
        width: 100%;
        padding: 10px 15px;
        /* border: 1px solid red; */

    }



}

/* min 768 md-md-md-md-md*/
@media screen and (min-width:768px) {
    #invite {
        /* background: pink; */
    }

}

/* min 1024 lg-lg*/
@media screen and (min-width:1024px) {
    #invite {
        /* background: gray; */
    }

    #invite >main {
        padding: 10px 15px;
        width: 82%;
        /* border: 1px solid red; */
    }

 
}

/* min 1280   xl-xl-xl-xl*/
@media screen and (min-width:1280px) {
    #invite >main {
        padding: 10px 30px;
    }

}

/* min 1536   2xl-2xl-2xl-2xl*/
@media screen and (min-width:1536px) {}




#dark .invite-container{
    background-color: #000;
}