/* Header */
header {
  width: 100%;
  height: 65px;
  /* background: var(--maincolor1); */
  background-color: #fff;
  box-shadow: 0 0 5px #0003;
  margin-bottom: 3px;
  user-select: none;
  /* border-bottom: 1px solid var(--maincolorborder1); */
}
#dark header {
  background-color: #141414;
  box-shadow: 0 0 5px #fff5;
}
header .all {
  width: 97%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}
header .all .menu {
  width: 65%;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
header .all .menu .logo {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
header .all .menu .logo img {
  width: 167px;
}
header .all .menu ul {
  margin: 0;
  padding: 0;
}
header .all .menu ul li {
  list-style: none;
  display: inline-block;
}
#dark header .all .menu ul li {
  color: #fff;
}
header .all .menu ul li a {
  padding: 0 30px;
  text-decoration: none;
}
header .profile {
  width: 34%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}
header .profile .account {
  /* width: 166px; */
  gap: 10px;
  padding: 0 10px;
  height: 46px;
  border: 1px solid var(--maincolor2);
  border-radius: 10px;
  color: var(--maincolor2);
  font-family: Poppins-Medium;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}
.ar header .profile .account {
  font-family: Shabnam;
}
header .profile .account .img-profile {
  display: flex;
  align-items: center;
}

.language {
  width: 110px;
  height: 46px;
  border: 1px solid var(--maincolorborder1);
  border-radius: 10px;
  color: #fff;
  font-family: Poppins-Medium;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin: 0 0.5rem;
}
.ar .language {
  font-family: Shabnam;
}
.language .css-13cymwt-control {
  background: unset;
  border: unset;
}

.language .css-1xc3v61-indicatorContainer {
  padding: 0;
}
.language .option_style {
  display: flex;
  align-items: center;
  color: #fff;
}
header .profile .theme {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--maincolorborder1);
  border-radius: 10px;
  width: 46px;
  height: 46px;
  cursor: pointer;
}

/* mobile */
.m-all {
  width: 97%;
  height: 100%;
  margin: auto;
  /* border-bottom: 1px solid var(--maincolorborder1); */
  display: none;
  align-items: center;
  justify-content: space-between;
}
.m-all .logo {
  width: 167px;
}
.m-all .m-profile {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--maincolorborder3);
  border-radius: 10px;
  cursor: pointer;
}
.m-all .m-profile svg {
  width: 18px;
}
#dark .m-all .m-profile svg {
  fill: #fff;
}
.m-all .logo img {
  width: 100%;
}
.m-all .m-menu {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--maincolorborder3);
  border-radius: 10px;
  cursor: pointer;
}
.m-all .m-menu img {
  width: 20px;
  filter: invert(1);
}
#dark .m-all .m-menu img {
  filter: invert(0);
}
/* menu-blur */
.menu-blur {
  width: 100%;
  height: 100%;
  background: #0000008c;
  backdrop-filter: blur(11px);
  position: fixed;
  z-index: 8888888;
  top: 0;
  display: none;
}
.menu-blur .top {
  width: 80%;
  height: 75px;
  display: flex;
  align-items: center;
  margin: auto;
}
.menu-blur .top .left {
  width: 30%;
}
.menu-blur .top .left .back-btn {
  width: 106px;
  height: 46px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}
.menu-blur .top .left .back-btn .mobile {
  display: none;
}
.menu-blur .top .right {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.menu-blur .top .right .theme {
  border: 1px solid #fff;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-blur .top .right .language {
  width: 155px;
  height: 46px;
  border: 1px solid var(--maincolorborder1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  padding: 1rem 0;
  margin: 0 10px;
  cursor: pointer;
}
.menu-blur .top .right .profile {
  width: 155px;
  height: 46px;
  border: 1px solid var(--maincolor2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  cursor: pointer;
}
.menu-blur .menu-list {
  width: 60%;
  margin: auto;
  padding: 4rem 0;
  direction: ltr;
}
.menu-blur .menu-list ul {
  margin: 0;
  padding: 0;
}
.menu-blur .menu-list ul li {
  width: 100%;
  list-style: none;
}
.menu-blur .menu-list ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  padding: 19px 0;
  color: #fff;
  font-family: Shabnam-Light;
  font-size: 18px;
  border-bottom: 1px solid #ffffff32;
}
.menu-blur .menu-list ul li a svg {
  fill: #fff;
  width: 8.93px;
  height: 15.63px;
}
.menu-blur .menu-list ul .active a {
  color: var(--maincolor2);
  font-family: Shabnam-Bold;
}
.menu-blur .menu-list ul .active a svg {
  fill: var(--maincolor2);
}
.menu-blur .info {
  width: 60%;
  margin: auto;
  position: absolute;
  bottom: 4rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.menu-blur .info div {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Poppins-Bold;
}
.ar .menu-blur .info div {
  font-family: Shabnam-Bold;
}
.menu-blur .info div svg {
  margin-left: 12px;
}
/* Responsive */
@media only screen and (max-width: 1400px) {
  header .all .menu ul li a {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1200px) {
  header .all .menu ul li a {
    padding: 0 15px;
  }
  header .all .menu .logo {
    margin-right: 15px;
  }
  header .all .menu {
    width: 73%;
  }
  header .profile {
    width: 26%;
  }
}
@media only screen and (max-width: 992px) {
  header .all {
    display: none;
  }
  header .m-all {
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .menu-blur .info {
    padding: 0;
    position: static;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }
  .menu-blur .menu-list {
    padding: 0;
  }
  .menu-blur {
    width:100%;
    height: 100%;
    background: #0000008c;
    backdrop-filter: blur(11px);
    position: absolute;
    z-index: 8888888;
    top: 0;
    display: none;
    right: 0;
  }
  .menu-blur .top .right .profile {
    display: none;
  }
  .menu-blur .top .right {
    width: 80%;
  }
  .menu-blur .top .left .back-btn {
    width: 106px;
    height: 46px;
    background: unset;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
  }
  .menu-blur .top .left .back-btn .text {
    display: none;
  }
  .menu-blur .top .left .back-btn svg {
    fill: #fff;
  }
  .menu-blur .top .left .back-btn .tablet {
    display: none;
  }
  .menu-blur .top .left .back-btn .mobile {
    display: block;
  }
  .menu-blur .info {
    flex-direction: column;
    align-items: end;
  }
}


@media only screen and (max-width: 640px) {
  header {
    height: 50px;
  }
  .m-all {
    width: unset;
    margin: auto 1rem;
  }
  .m-all .logo {
    width: 125px;
  }
}