.auth {
    width: 100%;
    display: flex;
}

.auth button:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.auth main {
    width: 82%;
    padding: 10px 30px;
    background-color: var(--light-gray-4);
}

.auth .begin {
    background-color: #fff;
    border-radius: 20px;
    margin: 1rem auto;
    padding: 1rem 2rem;
}

.auth .begin .container {
    margin: auto;
    margin-top: 1rem;
    align-items: end;
    padding-bottom: 1rem;
    gap: 0;
}

.auth .begin h2 {
    font-weight: bold;
    font-size: 18px;
}

.auth .begin ul {
    list-style: disc;
    list-style-position: inside;
}

.auth .begin .right img {
    max-width: 500px;
    padding: 1rem;
    padding-bottom: 0;
    margin: auto;
}

.auth .begin .left-big {
    display: flex;
    gap: 1rem;
}

.auth .begin .middle,
.auth .begin .left {
    background-color: #e8fcfa;
    padding: 1.5rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: calc(50% - .5rem) !important;
}

.auth .begin .middle {
    background-color: rgb(248, 241, 241);
    position: relative;
    overflow: hidden;
}

.auth .begin .middle span {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    font-size: 12px;
    background-color: rgb(246, 63, 63);
    color: #fff;
    transform: rotate(-45deg) translate(-35px, -10px);
    padding: 0 2rem;
}

.auth .begin .middle>div,
.auth .begin .left>div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.auth .begin button {
    background-color: var(--maincolor3);
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
}

.auth .begin button:disabled {
    opacity: 0.8;
    pointer-events: none;
}

.auth .begin .middle button {
    background-color: rgb(246, 63, 63);
}

.auth .begin .middle button:hover {
    background-color: rgb(240, 43, 43);
}

.auth .begin button:hover {
    background-color: var(--maincolor3hover);
}

.auth .status {
    background-color: #fff;
    border-radius: 20px;
    padding: 1rem 2rem 2.8rem 2rem;
    display: flex;
}

.auth .status>div {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    width: 400px;
    max-width: 100%;
}

.auth .status .stroke {
    display: block;
    height: 2px;
    flex-grow: 1;
    background-color: #eee;
}

.auth .status>div>div {
    padding: 10px;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.auth .status>div>div span {
    position: absolute;
    bottom: -1.8rem;
    width: max-content;
    color: #aaa;
}

.auth .status svg path {
    stroke: #aaa;
}

.auth .status.level-1 .level1 {
    background-color: var(--maincolord2);
}

.auth .status.level-1 .level1 span {
    color: var(--maincolord2);
}

.auth .status.level-1 .level1 path {
    stroke: #fff;
}

.auth .status.level-2 .level1,
.auth .status.level-2 .level2,
.auth .status.level-2 .stroke1 {
    background-color: var(--maincolord2);
}

.auth .status.level-2 .level1 span,
.auth .status.level-2 .level2 span {
    color: var(--maincolor2);
}

.auth .status.level-2 .level1 path,
.auth .status.level-2 .level2 path {
    stroke: #fff;
}

.auth .status.level-3 .level1,
.auth .status.level-3 .level2,
.auth .status.level-3 .level3,
.auth .status.level-3 .stroke {
    background-color: var(--maincolord2);
}

.auth .status.level-3 .level1 span,
.auth .status.level-3 .level2 span,
.auth .status.level-3 .level3 span {
    color: var(--maincolor2);
}

.auth .status.level-3 .level1 path,
.auth .status.level-3 .level2 path,
.auth .status.level-3 .level3 path {
    stroke: #fff;
}

.auth .status .level2.active path {
    stroke: #fff;
}

.auth form {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    margin: 1rem auto;
    position: relative;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.auth h1 {
    font-size: 22px;
    font-weight: bold;
    margin: 1rem 0;
    margin-left: auto;
}

.auth form .title {
    margin-left: auto;
    margin-bottom: 1rem;
}

.auth .container {
    display: flex;
    gap: 2rem;
}

.auth .container .left {
    width: calc(45% - 1rem);
    padding: 1.5rem;
}

.auth .container .right {
    display: flex;
    flex-direction: column;
    width: calc(55% - 1rem);
}

.auth .container .right .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    gap: 7px;
}

.auth .container .right .input-container span {
    color: red;
    font-size: 14px;
}

.auth .container .right input {
    background: white;
    border: 1px solid #e3e4f5;
    color: black;
    padding: 10px 13px;
    border-radius: 10px;
}

.auth .container .right input:hover,
.auth .container .right input:focus {
    border-color: #b2b4cf !important;
}

.auth .img-container img {
    border-radius: 1rem;
}

.auth .img-container label {
    display: block;
    padding: .8rem;
    border: 1px solid var(--maincolor3);
    color: var(--maincolor3) !important;
    margin-top: 1rem;
    border-radius: 10px;
    text-align: center;
    width: fit-content;
    cursor: pointer;
}

.auth #image_upload {
    width: 1px;
    height: 1px;
}

.auth form>button {
    background-color: var(--maincolor3);
    color: #fff;
    width: fit-content;
    margin: auto;
    padding: .8rem;
    width: 40%;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.auth .level-2 form>button {
    /* margin-top: 1.5rem; */
}

.auth form>button:hover {
    background-color: var(--maincolor3hover);
}

.auth .info {
    background-color: #effdfc;
    border-radius: 1rem;
    padding: 1rem;
    font-size: 14px;
}

.auth .info ul {
    list-style: disc;
    list-style-position: inside;
}

.auth .info div {
    display: flex;
    gap: 5px;
    margin-bottom: .6rem;
    color: #999;
}

.auth .info div svg {
    fill: #999;
}

.auth .level-2 .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.auth .level-2 .left {
    padding: 0 1rem;
}

.auth .level-2 form .title {
    padding: 0 1.5rem;
}

.auth .right input#code-melli {
    direction: ltr;
}

.auth .level-3 .right input {
    direction: ltr;
    margin-top: 7px;
}

.auth .level-3 .right .sheba {
    position: relative;
    margin-bottom: 1rem;
    margin-top: 7px;
}

.auth .level-3 .right .sheba input {
    width: 100%;
    margin: 0;
    padding-left: 2.5rem;
}

.auth .level-3 .right .sheba::before {
    content: 'IR';
    position: absolute;
    left: 1rem;
    top: calc(50% + 1px);
    transform: translateY(-50%);
    color: #999;
}

.auth .level-success form {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding-top: 2rem;
}

.auth .level-success img {
    width: 100px;
}

.auth .level-success h1 {
    margin: auto;
    text-align: center;
    padding: 0 1rem;
}

.auth .level-success p {
    padding: 0 1rem;
    text-align: center;
}

.auth .begin-status {
    flex-direction: column;
    padding: 1rem 2rem;
    gap: .5rem;
}

.auth .begin-status div {
    margin: 0;
    gap: 5px;
    width: unset;
    justify-content: unset;
    flex-wrap: wrap;
}

.auth .begin-status .declined-text {
    color: rgb(221, 27, 27);
}



@media screen and (max-width: 1400px) {
    .auth .begin .container {
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
    }

    .auth .container .right {
        /* width: 100%; */
    }
}

@media screen and (max-width: 1024px) {
    .auth {
        flex-direction: column;
    }

    .auth main {
        width: 100%;
        min-height: calc(100vh - 75px);
        padding: 1rem;
        padding-bottom: 6rem;
    }

    .auth .container {
        flex-direction: column;
    }

    .auth .container>div {
        width: 100% !important;
    }

    .auth .container .right {
        padding: 1.5rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    .auth form {
        padding: 1rem 0;
    }

    .auth .container {
        gap: 0;
    }

    .auth form h1 {
        margin: 0;
        margin-top: .5rem;
    }

    .auth form>button {
        width: calc(100% - 3rem);
    }

    .auth .level-2 .left {
        padding: 1.5rem;
        padding-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .auth .begin .left-big {
        flex-direction: column;
    }

    .auth .begin h1,
    .auth .begin p {
        text-align: center;
    }

    .auth .begin .middle,
    .auth .begin .left {
        width: 100% !important;
    }

    .auth .begin .right img {
        max-width: 100%;
    }

    .auth .status {
        padding-bottom: 2.7rem;
    }

    .auth .status>div>div {
        width: 3rem;
        height: 3rem;
    }

    .auth .status>div>div span {
        font-size: 14px;
        bottom: -1.6rem;
    }
}



/* ////////////// Dark */
#dark .auth main {
    background-color: var(--dark-gray-4);
}

#dark .auth .status,
#dark .auth form {
    background-color: #000;
}

#dark .auth .info {
    background-color: #1d1d1d;
    color: #fff;
}

#dark .auth .begin {
    background-color: #000;
}

#dark .auth .begin {
    color: #fff;
}

#dark .auth .begin .left {
    background-color: #002b28;
}

#dark .auth .begin .middle {
    background-color: rgb(60, 0, 0);
}

#dark .auth .level-success form {
    color: #fff;
}

#dark .auth .begin-status {
    color: #fff;
}