#light #wallet-rial{
    background:var(--light-gray-4) ;
}
#dark #wallet-rial{
   background: var(--dark-gray-4);
}
/* ///////////////////////////////////////////////////////////////////////// */
#light #wallet-rial .deposit_withdraw .two_button button{
    background: var(--light-gray-4);
    color: black;
}
#dark #wallet-rial .deposit_withdraw .two_button button{
    background: var(--dark-gray-4);
    color: white;
}