/* //////////// new-password-right ///////////////////*/
#light .new-password-right{
    background: white ;
}
#dark .new-password-right{
    background: black ;
}
/* //////////////// h1 ///////////////////////*/
#light .new-password-right h1{
    color: black;
}
#dark .new-password-right h1{
   color: white;
}
/* //////////////// p ///////////////////////*/
#light .new-password-right p{
    color: var(--dark-gray-2);
}
#dark .new-password-right p{
   color: var(--light-gray-2);
}
/* //////////////// ///////////////////////*/
#light .new-password-right .eye{
    color: black;
}
#dark .new-password-right .eye{
   color: white;
}



/* //////////////submit button///////////// */
#light .new-password-right form .submit-form button{
    background: var(--light-yellow-1);
    color: white;
}
#dark .new-password-right form .submit-form button{
    background: var(--dark-yellow-1);
    color: white;
}
/* //////////// */
#light .new-password-right form > .recently span{
    color: var(--dark-gray-2);
}
#dark .new-password-right form > .recently span{
    color: var(--light-gray-2);
}