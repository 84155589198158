/* terms-section-one */
#terms-section-one {
  padding: 4rem 0;
}
#dark #terms-section-one {
  color: #fff;
}
#terms-section-one .design1 {
  position: absolute;
  top: 0;
  z-index: -2;
}
#terms-section-one .all {
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#terms-section-one .all .title {
  font-size: 27px;
  text-align: center;
}
#terms-section-one .all .des {
  font-size: 16px;
  font-family: Shabnam-FD;
  padding: 1rem;
  text-align: center;
}
/* terms-section-two */
#terms-section-two {
  padding: 4rem 0;
}
#dark #terms-section-two {
  color: #fff;
}
#terms-section-two .all {
  width: 80%;
  margin: auto;
}
#terms-section-two .all .title {
  font-size: 24px;
  padding-bottom: 17px;
  margin-top: 25px;
}
#terms-section-two .all .des {
  font-size: 16px;
  padding-bottom: 17px;
}

@media only screen and (max-width: 768px) {
  #terms-section-one {
    padding: 1rem 0;
  }

  #terms-section-one .all {
    width: 90%;
  }
  #terms-section-two {
    padding: 1rem 0;
  }
}
