
/* Root */
:root {
  --maincolor1: #273141;
  --maincolor2: #f6b33f;
  --maincolor2hover: #eea21e;
  --maincolor2light: #F8F3E1;
  --maincolor2lightDarkTheme: #2e2400;
  --maincolor3: #00C0AF;
  --maincolor3hover: #00b3a4;
  --maincolor3light: #00c0b018;
  --maincolor3lighthover: #00c0b023;
  --maincolor4: #F7F8FA;
  --maincolorborder1: #707070;
  --maincolorborder2: #EDEEF1;
  --maincolorborder3: #d1e3e9;
  --maincolorfont1: #C2C2C2;
  --maincolorfont2: #333333;

  --maincolorred: #F63F3F;

  /*  */
  --maincolord1: #273141;
  --maincolord2: #f9cc0b;
  --maincolord2hover: #ebc10a;
  --maincolord3: #00C0AF;
  --maincolord4: #1b1b1b;
  --maincolorborderd1: #707070;
  --maincolorborderd2: #EDEEF1;
  --maincolorfontd1: #312626;
  --maincolorfontd2: #cfcfcf;

  /* مهدی */
   /* light colors */
   --light-yellow-1: #f9cc0b;
   --light-yellow-2: #f6b33f;
   --light-cyan-1: #00c0af;
   --light-gray-1: #333333;
   --light-gray-2: #a5a5a5;
   --light-gray-3: #d1d1d1;
   --light-gray-4: #f7f8fa;
   /* dark colors  */
   --dark-yellow-1: #f9cc0b;
   --dark-yellow-2: #f6b33f;
   --dark-cyan-1: #00c0af;
   --dark-gray-1: #cfcfcf;
   --dark-gray-2: #707070;
   --dark-gray-3: #1b1b1b;
   --dark-gray-4: #141414;


}
#dark {
  background: #000;
}
/* Fonts */
/* @font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/Poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: Poppins-Medium;
  src: url(../public/fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
} */

/* All */



@media only screen and (max-width: 768px) {
  html,
  body {
    min-height: 100%;
  }
}


::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

#dark ::-webkit-scrollbar-track {
  background: #555;
  border-radius: 10px;
}

#dark ::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}

#dark ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/* mehdi mehdi mehdi mehdi mehdi mehdi mehdi mehdi mehdi mehdi mehdi mehdi*/
/* ///////////////////////////////////// */
#light label {
  font-size: 14px;
  color: var(--dark-gray-2);
}

#light input[type=text],#light input[type=password] {
  background: white;
  /* border: 1px solid #e3e4f5; */
  border: 1px solid var(--maincolorborder2);
  color: black;
}
#light input[type=text]:hover,#light input[type=password]:hover,
#light input[type=text]:focus,#light input[type=password]:focus {
  border-color: #dadce2;
}
input:disabled {
  pointer-events: none;
}
#light .toggleColor{
  color: black;
}
#light .toggleBg{
  background: white;
}

/* ///////////////////////////////////// */
#dark label {
  font-size: 14px;
  color: var(--light-gray-2);
}

#dark input[type=text],#dark input[type=password] {
  background: black;
  border: 1px solid #e3e4f5;
  color: white;
}
#dark .toggleColor{
  color: white;
}
#dark .toggleBg{
  background: black;
}

/* ///////////////////////////////////// */
.ltr{
  direction: ltr !important;
}
.rtl{
  direction: rtl !important;
}


button:disabled {
  opacity: 0.4;
}