.mobile_nav{
  position: relative;  
    
}
/* عکس باید وسط باشد */
.mobile_nav >a{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    
    
}
/* ///////////////////////////////////////////////////////// */
    
.mobile_nav>section> span{
    display: flex;
    border: 1px solid var(--maincolorborder3);
    border-radius: 7px;
   
}
.mobile_nav >section{
    /* border: 1px solid blue; */
}
/* زنگوله */
.mobile_nav section.bell{
    position: relative;
    width: 37px;
    height: 37px;
    border-radius: 8px;
    border: 1px solid var(--maincolorborder3);
}
    
/* ///////////////////پرده سیاه برای  سمت راست////////////////// */
.mobile_nav .mobileRight_DarkScreen{
    position: fixed;top: 0;right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.58);
    backdrop-filter: blur(10px);
    z-index: 500;
}

.mobile_nav .mobileRight_DarkScreen >ul>li{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-bottom: .5px solid white;
    color: white;
    margin-bottom: 5px;
    text-align: center;
}
.mobile_nav .mobileRight_DarkScreen >ul>li>a{
    /* background: orange; */
}
/* position: absolute */
/* position: absolute */
.mobile_nav .mobileRight_DarkScreen>section{
    position: absolute; bottom: 50px;right: 0;
    width: 100%;
    height: 100px;
    /* border: 1px solid red; */
    color: white;
}
/* ///////////////////پرده سیاه برای سمت چپ////////////////// */
.mobile_nav .mobileLeft_DarkScreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.58);
    backdrop-filter: blur(10px);
    z-index: 500;
}
/* /////////////////////پرده سیاه برای تبلت/////////// */
.mobile_nav .tablet_DarkScreen {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 1200px;
    background: rgba(0, 0, 0, 0.58);
    backdrop-filter: blur(10px);
    z-index: 500;
    /* border: 1px solid red; */
}

.mobile_nav .tablet_DarkScreen nav {
    /* border: 1px solid red; */
}

.mobile_nav .tablet_DarkScreen ul.tablet_list1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    width: 60%;
    /* border: 1px solid red; */
}

.mobile_nav .tablet_DarkScreen ul.tablet_list1>li {
    display: flex;
    align-items: center;
    width: 47%;
    height: 46px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    column-gap: 15px;
    margin-bottom: 20px;
}
/* ////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// */

.mobile_nav .tablet_DarkScreen ul.tablet_list2 {
    width: 60%;
    /* border: 1px solid red; */
    color: white
}

.mobile_nav .tablet_DarkScreen ul.tablet_list2>li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    text-align: center;
    border-bottom: .5px solid white;
    margin-bottom: 5px;
}
.mobile_nav .tablet_DarkScreen ul.tablet_list2>li a{
  
}
    
    
/* ////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// */
/* ///////////////////// پروفایل منو برای تبلت و دسکتاپ  /////////// */
.mobile_nav ul.profile_menu {
    cursor: auto;
    position: absolute; z-index: 1699;
    top: 48px;
    z-index: 10;
    width: 245px;height: 121px;
    background: white;
    box-shadow: 1px 1px 5px rgb(197, 194, 194);
    border-radius: 20px;
    
}
.mobile_nav ul.profile_menu li{
    height: 37.5px;
    /* border: 1px solid black; */
}
/* /////////////////////////// اعلانات برای حالت موبایل و تبلت////////////////////////////// */



/* ////////////////////ریسپانسیو//////////////////////// */
/* ////////////////////ریسپانسیو//////////////////////// */

/* max 640     mobile-mobile */
@media screen and (max-width:640px) {
    .mobile_nav{
        height: 50px;
    }
    .mobile_nav >section> span{
        width: 37px;
        height: 37px;
    }
    .mobile_nav img.logo{
        width: 125px;
    }
    
    .tablet_DarkScreen{
        display: none;
    }
    .mobile_nav ul.profile_menu{
        display: none;
    }
    /* اعلانات */
    .mobile_nav ul.notification-list{
        position: fixed; z-index: 500;
        
        width: 90%; height: 500px;
        padding: 22px 16px;
        margin: auto;
        overflow-y: scroll;
        border-radius: 20px;
        box-shadow: 1px 1px 5px rgb(126, 122, 122);
    }
    .mobile_nav ul.notification-list>li{
        min-height: 105px;
        /* border: 1px solid blue; */
        margin-bottom: 10px;
        border-bottom: 1px solid #e3e4e8;
    }
    .mobile_nav ul.notification-list>li button{
        border: 1px solid #e3e4e8;
        padding: 5px 7px;
        border-radius: 5px;
        font-size: 14px;
    }
    
    #authorization_box-tablet {
        display: none;
    }
}

/* min 640 sm-sm-sm-sm-sm*/
@media screen and (min-width:640px) {
    .mobile_nav{
        height: 75px;
    }
    .mobile_nav >section> span{
        width: 46px;
        height: 46px;
        
    }
    .mobile_nav .bell{
        width: 46px !important;
        height: 46px !important;
        
    }
    .tablet_DarkScreen{
        display: none;
    }
    .mobile_nav ul.profile_menu{
        display: none;
    }
    /* اعلانات */
    .mobile_nav ul.notification-list{
        position: fixed; z-index: 500;
        
        width: 400px ; height: 500px;
        padding: 22px 16px;
        
        overflow-y: scroll;
        border-radius: 20px;
        box-shadow: 1px 1px 5px rgb(212, 208, 208);
    }
    .mobile_nav ul.notification-list>li{
        min-height: 105px;
        /* border: 1px solid blue; */
        margin-bottom: 10px;
        border-bottom: 1px solid #e3e4e8;
    }
    .mobile_nav ul.notification-list>li button{
        border: 1px solid #e3e4e8;
        padding: 5px 7px;
        border-radius: 5px;
        font-size: 14px;
    }
  
}
/* min 768 md-md*/
@media screen and (min-width:768px) {
    .mobileRight_DarkScreen{
        display: none !important;
    }
    .mobileLeft_DarkScreen{
        display: none !important;
    }
    .tablet_DarkScreen{
        display: block;
    }
    .mobile_nav ul.profile_menu{
        display: flex;
    }
    .mobile_nav ul.notification-list{
        display: block;
    }
}
/* min 1024 lg-lg*/
@media screen and (min-width:1024px) {
    .mobile_nav{
        display: none !important;
    }
    .tablet_DarkScreen{
        display: none;
    }
    .mobile_nav ul.profile_menu{
        display: none;
    }
    .mobile_nav ul.notification-list{
        display: none;
    }
}
/* min 1280   xl-xl*/
@media screen and (min-width:1280px) {
}
/* min 1536   2xl-2xl*/
@media screen and (min-width:1536px) {
   
}