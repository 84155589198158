#wallet{
    position: relative;
    width: 100%;
    min-height: 100vh;
    /* border: 1px solid green; */
}
#wallet >main{
    min-height: 100vh;
    padding: 10px 30px;
    /* border: 1px solid blue; */
}

/* userData *//* userData *//* userData *//* userData */

#wallet >main .wallet_userData{
    width: 100%;
    border: 1px solid #e6e8f2;
    padding: 10px 15px;
    border-radius: 15px;
}
#wallet >main .wallet_userData>ul{
    display: flex;
    flex-wrap: wrap;
   
    /* border: 1px solid red; */
}
#wallet >main .wallet_userData>ul>li{
    /* border: 1px solid red; */
     height: 50%;
     text-align: center;
}
#wallet >main .wallet_userData>ul>li>span{
    display: block;
    margin-bottom: 5px;
}


/* digital-wallet *//* digital-wallet */
/* digital-wallet */
#wallet >main .digital-wallet{
   
    padding: 15px;
    /* border: 1px solid #EDEEF1; */
    border-radius: 20px;
    /* margin-bottom: 100px; */
    
}
#wallet >main .digital-wallet>div:nth-of-type(1) button{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 116px;
   height: 48px;
   border-radius: 10px;
}
   
  
#wallet >main .digital-wallet>div:nth-of-type(1) button>*{
    /* border: 1px solid green; */
}


/* table *//* table *//* table */
/* table *//* table *//* table */
#wallet .digital-wallet .table-parent{

}

#wallet .digital-wallet .table-parent>table thead tr{
  /* border: 1px solid black; */
  height: 25px !important;

}
  
#wallet .digital-wallet .table-parent>table tbody tr{
    height: 60px;
    border-bottom: 1px solid #edeef1;
    /* border: 1px solid black; */
}
/* صفحه بندی *//* صفحه بندی *//* صفحه بندی */
/* صفحه بندی */
.paginate{
    width: fit-content;
    display: flex;
    /* background: gray; */
    padding: 0 10px;
    column-gap: 10px;
}
.paginate>li{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: #eee;
}
.active-paginate{
    border: 2px solid var(--maincolor2);
    background-color: var(--maincolor2) !important;
    font-weight: bolder;
    color: white;
    pointer-events: none;
}







/* ////////////////////////فیلتر /////////////////////////// */
/* ////////////////////////فیلتر /////////////////////////// */
#wallet .filter_parent{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.58);
    backdrop-filter: blur(10px);
    z-index: 500;
}
#wallet .filter_parent>section{
    width: 636px;
    height: 327px;
    border-radius: 10px;
    padding: 15px;
}
#wallet .filter_parent>section select{
    border-radius: 10px;
}







/* /////////////// ریسپانسیو////////////////// */
/* /////////////// ریسپانسیو////////////////// */

/* max 640     mobile-mobile */
@media screen and (max-width:640px) {

    #wallet >main{
        width: 100% !important;
        padding: 10px 15px;
        /* background: gray; */
        margin-bottom: 100px;
        /* border: 1px solid red; */
    }
    #wallet >main .wallet_userData{
        height: 210px;
    }
    #wallet >main .wallet_userData>ul{
        justify-content: space-between;
        align-content: space-between !important;
        width: 100%;
        height: 120px;
        /* border: 1px solid black; */
        padding: 0px 14%;
    }
    #wallet >main .wallet_userData>ul>li{
        width: 50%;
        
        /* border: 1px solid black; */
    }
    #wallet >main .wallet_userData .two-link{
        justify-content: space-between;
        width: 100%;
        height: 46px;
        /* border: 1px solid red; */
    }
    #wallet >main .wallet_userData .two-link a{
        width: 47%;
    }

    #wallet >main .digital-wallet>div:nth-of-type(1){
        /* background: olive; */
        height: 100px;
    }
    #wallet >main .digital-wallet>div:nth-of-type(1) h3{
        width: 100%;
        font-size: 16px;
        /* border: 1px solid black; */
        margin-bottom: 5px;
    }
    #wallet >main .digital-wallet>div:nth-of-type(1)>div{
        width: 100%;
        align-items: center;
        justify-content: space-between;
        /* border: 1px solid black; */
    }
    #wallet >main .digital-wallet>div:nth-of-type(1) button{
        width: 23%;
    }
    #wallet .digital-wallet .table-parent{
        overflow-x: scroll;
        overflow-y: hidden;
        /* border: 1px solid green; */
    }
    #wallet .digital-wallet .table-parent>table{
        width: 900px;
    }
    /* filter_parent */
    #wallet .filter_parent>section{
        width: 90%;
        height: 500px;
    }
    #wallet .filter_parent .filter-box>section{
        width: 100%;
        /* border: 1px solid black; */
    }
    #wallet .filter_parent button.apply-filter{
        
        width: 100%;
    }
}

/* min 640   sm-sm*/
@media screen and (min-width:640px) {
    #wallet >main{
        width: 100% !important;
        padding: 10px 15px;
        padding-bottom: 30px;
    }
    #wallet >main .wallet_userData{
        height: 152px;
    }
    #wallet >main .wallet_userData>ul{
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 65px;
        /* border: 1px solid black; */
        padding: 0px 30px;
    }
    #wallet >main .wallet_userData>ul>li{
        height: fit-content;
        /* border: 1px solid black; */
    }
    #wallet >main .wallet_userData .two-link{
        justify-content: space-between;
        width: 100%;
        height: 46px;
        /* border: 1px solid red; */
    }
    #wallet >main .wallet_userData .two-link a{
        width: 47%;
    }
    #wallet >main .digital-wallet>div:nth-of-type(1){
        align-items: center;
        justify-content: space-between;
        /* background: olive; */
        height: 50px;
    }
    #wallet >main .digital-wallet>div:nth-of-type(1) h3{
       
        font-size: 16px;
        /* border: 1px solid black; */
        margin-bottom: 5px;
    }
    #wallet >main .digital-wallet>div:nth-of-type(1)>div{
        
        align-items: center;
        justify-content: space-between;
        /* border: 1px solid black; */
    }
    #wallet >main .digital-wallet>div:nth-of-type(1) button{
        width: 90px;
    }
    #wallet .digital-wallet .table-parent{
        overflow-x: scroll;
        overflow-y: hidden;
        /* border: 1px solid green; */
    }
    #wallet .digital-wallet .table-parent>table{
        width: 900px;
    }
}
/* min 768 md-md*/
@media screen and (min-width:768px) {
    
    
}
/* min 1024           lg-lg*/
@media screen and (min-width:1024px) {
    #wallet >main{
        width: 82% !important;
        padding: 10px 30px;
        /* border: 1px solid blue; */
        padding-bottom: 30px;
    }
    #wallet >main .wallet_userData{
        height: 136px;
    }
    #wallet >main .wallet_userData>ul{
        justify-content: space-evenly;
        align-items: center;
        width: 80%;
        height: 100%;
        /* border: 1px solid black; */
        padding: 0px 30px;
    }
    #wallet >main .wallet_userData>ul>li{
        height: fit-content;
        /* border: 1px solid black; */
    }
    #wallet >main .wallet_userData .two-link{
        justify-content: space-between;
        width: 20%;
        height: 100%;
        /* border: 1px solid red; */
    }
    #wallet >main .wallet_userData .two-link a{
        width: 100%;
        height: 46px;
    }
    #wallet >main .digital-wallet>div:nth-of-type(1) button{
        width: 116px;
    }
    #wallet .digital-wallet .table-parent{
        
        overflow-x: scroll;
        overflow-y: hidden;
        /* border: 1px solid green; */
    }
    #wallet .digital-wallet .table-parent>table{
       width: 1100px !important;
    }
}
/* min 1280   xl-xl*/
@media screen and (min-width:1280px) {
    #wallet .digital-wallet .table-parent{
        
        overflow: hidden;
        
    }
    #wallet .digital-wallet .table-parent>table{
       width: 100% !important;
    }
}
/* min 1536   2xl-2xl*/
@media screen and (min-width:1536px) {
   
}


