#login{
    /* border: 2px solid red; */
}
.grand-parent{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    /* border: 2px solid red; */
}
.login-parent{
    display: flex;
    flex-wrap: wrap !important;
    height: 565px;
    /* border: 1px solid red; */
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 1px 1px 20px rgba(79, 79, 79, 0.18);
}
/* right *//* right */
/* right *//* right */


.login-parent .login-right{
    width: 50%;
    height: 100%;
    /* border: 1px solid red; */
    padding: 14px;
}
.login-parent .login-right h1{
    height: 30px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 22px;
    font-weight: bolder;
}
.login-parent .login-right form{
    position: relative;
    width: 100%;
    height: 372px;
    /* border: 1px solid red; */
}
    
.login-right form>div{
    width: 100%;
    height: 72px;
    /* border: 1px solid purple; */
    margin-bottom: 15px;
}
.login-right form>div:nth-of-type(2){
    position: relative;
    /* border: 1px solid red; */
}
.login-right form>div:nth-of-type(2) span{
    height: 46px;
    position: absolute;display: flex;
    align-items: center;
    /* background: red; */
    bottom: 0px;
    
}
.login-right form>div label{
    display: flex;
    height: 19px;
    font-size: 14px;
    margin-bottom: 7px;
}
    
.login-right form>div:nth-of-type(2) input{
    height: 46px;
    border-radius: 10px;
    padding: 7px 10px;
}

/* section */
/* section */
.login-right form>section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
   
    margin-bottom: 15px;
    /* border: 1px solid red; */
}
.login-right form>section label{
    display: flex;
    height: 32px;
    align-items: center;
    font-size: 14px;
    column-gap: 7px;
    /* border: 1px solid red; */
}
.login-right form>section a{
    color:#f6b33f ;
    font-size: 14px;
}
/* submit-form */
/* submit-form */
.login-right form .submit-form{
    width: 100%;
    height: 46px !important;
}
.login-right form .submit-form button{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
}
/* recently *//* recently */
/* recently *//* recently */
.login-right form > .recently{
    position: absolute;
    bottom: 0px ; right: 0;
    margin-bottom: 0 !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 51px !important;
    font-size: 14px;
    border-top: 1px solid #e3e4f5;
}
.login-right .recently span a{
    color: #f6b33f !important;
}



/* left *//* left *//* left *//* left *//* left *//* left */
.login-left{
    background: #0a0f21;
    background-image: url(../../../assests/image/Login-IMG.png);
    background-size:contain;
    background-repeat: no-repeat;
    
    background-position: center;
    width: 50%;
    height: 100%;
    
    /* border: 1px solid red; */
    padding: 14px;
}
.login-left .fake{
    width: 100%;
    height: 72%;
    /* background: white; */
}
.login-left h2{
    /* border: 1px solid white; */
    margin-bottom: 10px;
}
.login-left h2 span{
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}
.login-left h2 span:nth-of-type(1){
   color: #f6b33f;
   font-size: 16px;
}
.login-left h2 span:nth-of-type(2){
   color: white;
   font-size: 20px;
}
.login-left p{
    color: white;
    font-size: 14px;
    text-align: center;
    font-weight: 100;
    /* border: 1px solid white; */
    padding: 0 10px;
}


#login .option_style{
    direction: ltr;
    display: flex !important;
    justify-content: flex-start;
    column-gap: 10px;
    height: 100%;
    font-size: 12px;
    /* background: gray; */
    
}
/* responsive ریسپانسیو */
/* responsive ریسپانسیو */
/* responsive ریسپانسیو */

/* MAX 640 */
@media screen and (max-width: 640px) {
     body{
        /* background: rgb(209, 200, 204); */
     }
     .login-parent{
        align-content: flex-start;
        width: 90%;
        justify-content: center;
        height: 465px;
        /* border: 1px solid black; */
        border-radius: 20px;
     }
     
    .login-left{
        width: 100%;
        height: 110px;
        background-size:124px 110px;
        background-position: left;
        margin-bottom: 20px;
    }
    .login-left .fake,.login-left p{
        display: none;
    } 
    .login-left h2 span{
        /* border: 1px solid white; */
        justify-content: flex-start;
    }
    .login-parent .login-right{
        width: 90%;
        height: 310px;
        order: 1;
        /* border: 1px solid orange; */
        padding: 0;
    }
    .login-parent .login-right h1{
        margin: 0;
        /* border: 1px solid orange; */
        margin-bottom: 20px;
    }
    .login-parent .login-right form{
        /* border: 1px solid red; */
    }
    .login-parent .login-right form .recently{
        position: fixed;
        width: 90%;
        right: 5%;
        bottom: 20px;
        /* border: 1px solid red; */
    }





}
/* MIN 640 */
@media screen and (min-width: 640px) {
    body{
        /* background: rgb(112, 216, 176); */
     }
    .login-parent{
      width: 90% !important;
      max-width: 704px !important;
    }
}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 1024px) {
    body{
        /* background: rgb(206, 164, 86); */
     }
     .login-parent{
        width: 842px !important;
        max-width: 842px !important;
      }
}
@media screen and (min-width: 1280px) {

}
@media screen and (min-width: 1536px) {

}


