.single-blog .content{
   width: 60%;
   /* border: 1px solid red; */
}

.single-blog .content img{
    width: 100%;
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
}

.single-blog p,
.single-blog span {
    font-family: unset !important;
}

#dark .single-blog {
    color: #fff;
}

@media screen and (max-width: 768px) {
    .single-blog .content {
        width: 90%;
    }
}