#reports {
    position: relative;
    width: 100%;
    min-height: 100vh;
    user-select: none;
    /* border: 2px solid purple; */
}

/* /////////////////// main ///////////////////////// */
/* /////////////////// main ///////////////////////// */
#reports >main {
    min-height: 100vh;
    /* border: 1px solid red; */
}
#reports table thead tr {
   height: 45px;
}
#reports table tbody tr {
   height: 55px;
   border-bottom: 1px solid #edeef1;
}
#reports .table_parent{
    overflow-x: scroll;
    overflow-y: hidden;
}

/* //////////////////responsive ریسپانسیو///////////////////*/
/* //////////////////responsive ریسپانسیو///////////////////*/
/* //////////////////responsive ریسپانسیو///////////////////*/


/* max 640     mobile-mobile */
@media screen and (max-width:640px) {
    

    #reports >main {
        width: 100%;
        padding: 0 15px;
    }
    #reports .table_parent table{
        width: 1000px !important;
    }
        
}


  


/* min 640 sm-sm-sm-sm-sm*/
@media screen and (min-width:640px) {
    #reports {
        /* background: rgb(193, 193, 142); */
    }
    #reports >main {
        width: 100%;
        padding: 0 15px;
        /* border: 1px solid red; */

    }
    #reports .table_parent table{
        width: 1000px;
    }



}

/* min 768 md-md-md-md-md*/
@media screen and (min-width:768px) {
    #reports {
        /* background: pink; */
    }

}

/* min 1024 lg-lg*/
@media screen and (min-width:1024px) {
    #reports {
        /* background: gray; */
    }

    #reports >main {
        padding: 10px 15px;
        width: 82%;
        /* border: 1px solid red; */
    }

 
}

/* min 1280   xl-xl-xl-xl*/
@media screen and (min-width:1280px) {
    #reports >main {
        padding: 10px 30px;
    }
    #reports .table_parent {
        overflow: hidden;
    }
    #reports .table_parent table{
        width: 100% !important;
    }
}

/* min 1536   2xl-2xl-2xl-2xl*/
@media screen and (min-width:1536px) {}