.mm{
    /* border: 1px solid red; */
    padding: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgb(221, 213, 213);
}



.option_style_2 {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 100%;
    font-size: 12px;
    /* background: #00c0af; */
    padding: 0 10px;
}