#light #dashboard{
    background: var(--light-gray-4);
}
#dark #dashboard{
    background: var(--dark-gray-4);
}
/* ///////////////////////////////////////////// */
#light #dashboard .middle-right{
    background: white;
}
#dark #dashboard .middle-right{
    background: black;
}
/* ///////////////////////////////////////////// */
#light #dashboard .four_box section .content{
    background: white;
    color: black;
}
#dark #dashboard .four_box section .content{
    background: black;
    color: white;
}
/* ///////////////////////////////////////////// */
#light #dashboard .invite-friends{
    background: white;
   
}
#dark #dashboard .invite-friends{
    background: black;
    
}
/* ///////////////////////////////////////////// */
#light #dashboard .business-history{
    background: white;
   
}
#dark #dashboard .business-history{
    background: black;
    
}
/* ///////////////////////////////////////////// */
#light #dashboard .business-history th{
    color: var(--dark-gray-2);
   
}
#dark #dashboard .business-history th{
    color: var(--light-gray-2);
    
}
/* ///////////////////////////////////////////// */
#light #dashboard .business-history tbody td{
    color: black;
   
}
#dark #dashboard .business-history tbody td{
    color: white;
    
}