/* ساید بار */
aside.main-sidebar{
   width: 18%;
   min-height: 100vh;
   padding: 30px 25px;
   background: white;
}
/* logo and bars *//* logo and bars */
/* logo and bars *//* logo and bars */
aside.main-sidebar>div:nth-of-type(1){
   position: relative;
   width: 100%;
   height: 33px;
   /* border: 1px solid black; */
}
aside.main-sidebar>div:nth-of-type(1)>section{
   position: absolute;top: 0;right: 0;
   width: 100%;
   height: 100%;
   /* background: orange; */
}
aside.main-sidebar>div:nth-of-type(1)>section>img{
   width: 50%;
}
aside.main-sidebar>div:nth-of-type(1)>span{
   display: flex;
   align-items: center;
   justify-content: flex-end;
   width: 40px;
   height: 100%;
   /* background: green; */
}
aside.main-sidebar>div:nth-of-type(1)>span svg{
  font-size: 22px;
}




/* total-wealth *//* total-wealth */
/* total-wealth */
.main-sidebar .total-wealth{
    height: 100px;
    border: 1px dotted #d1d1d1;
    /* box-shadow: 1px 1px 5px gray; */
    border-radius: 10px;
    overflow: hidden;
}
.main-sidebar .total-wealth>div:nth-of-type(1){
   background: #273141;
   position: relative;
}
.main-sidebar .total-wealth>div:nth-of-type(1)> span{
    position: absolute;
    top: 6px;
    right: calc(50% - 16px);
    background:#362d1e ;
    border-radius: 50%;
    border: 1.5px solid white;
}


/* list_sidebar */ /* لیست */
 /* list_sidebar */
.main-sidebar ul#list_sidebar> li{
  
   height: 40px;
   background: #f5f6f9;
   border-radius: 10px;overflow: hidden;
   margin-bottom: 15px;
   
}

.main-sidebar ul#list_sidebar> li svg path.active {
   fill: #f6b33f !important;
}

#dark .main-sidebar ul#list_sidebar> li {
   background-color: #222;
   color: #fff;
}

#dark .main-sidebar ul#list_sidebar> li svg path {
   fill: #fff;
}

.invite-span-active path {
   fill: #f6b33f !important;
}
   
   
.main-sidebar ul#list_sidebar li>a{
   display: flex; width: 100%;height: 100%;
   align-items: center;
   column-gap: 10px;
   padding: 0 16px;
   /* border: 1px solid red; */
}