#contact-section-one {
  padding: 4rem 0;
}
#contact-section-one .all {
  width: 70%;
  height: 324.18px;
  background: url(../image/contact-page/Earth.png);
  background-size: cover;
  margin: auto;
}
#dark #contact-section-one .all {
  color: #fff;
}
#contact-section-one .all .title {
  font-size: 27px;
  font-family: Poppins-Bold;
  text-align: center;
  padding-top: 45px;
}
.ar #contact-section-one .all .title {
  font-family: Shabnam-Bold;
}
#contact-section-one .all .des {
  font-size: 16px;
  font-family: Poppins-Regular;
  text-align: center;
}
.ar #contact-section-one .all .des {
  font-size: 16px;
  font-family: Shabnam-light;
  text-align: center;
}
/* contact-section-two */
#contact-section-two {
  margin-top: -70px;
  padding-bottom: 4rem;
}
#contact-section-two .all {
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 1rem;
}
#contact-section-two .all .grid-item {
  display: flex;
  justify-content: center;
}
#contact-section-two .all .grid-item .carts {
  padding: 2rem;
  /* border: 1px solid var(--maincolorborder3); */
  /* width: 385px; */
  border-radius: 20px;
  background: var(--maincolor4);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#dark #contact-section-two .all .grid-item .carts {
  background: var(--maincolord4);
  color: #fff;
  border: 1px solid #555;
}
#contact-section-two .all .titles {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
#contact-section-two .all .title {
  padding-left: 25px;
}
.ar #contact-section-two .all .title {
  padding-right: 25px;
}
/* contact-section-three */
#contact-section-three {
  padding: 4rem 0;
}
#contact-section-three form {
  width: 70%;
  margin: auto;
  padding: 2rem;
  border-radius: 20px;
  border: 1px solid var(--maincolorborder3);
  background: var(--maincolor4);
}
#dark #contact-section-three form {
  background: var(--maincolord4);
  color: #fff;
  border: 1px solid #555;
}
#contact-section-three form .title {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
#contact-section-three form .title .text {
  padding-left: 25px;
}
.ar #contact-section-three form .title .text {
  padding-right: 25px;
}
#contact-section-three form .inputs {
  padding: 2rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
}
#contact-section-three form .inputs div {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}
#contact-section-three form .inputs div input {
  height: 46px;
  border-radius: 10px;
  border: 1px solid var(--maincolorborder2);
}
#dark #contact-section-three form .inputs div input {
  background: #000;
  border: 1px solid #555;
}
#contact-section-three form .inputs div label {
  padding-bottom: 9px;
  font-size: 14px;
  color: var(--maincolorborder1);
}
#contact-section-three form .message {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  padding-bottom: 2rem;
}
#contact-section-three form .message label {
  padding-bottom: 9px;
  font-size: 14px;
  color: var(--maincolorborder1);
}
#contact-section-three form .message textarea {
  height: 114px;
  border-radius: 10px;
  border: 1px solid var(--maincolorborder2);
}
#dark #contact-section-three form .message textarea {
  background: #000;
  border: 1px solid #555;
}
#contact-section-three form .end {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
#contact-section-three form .end button {
  width: 316px;
  height: 46px;
  border-radius: 10px;
  border: 0;
  box-shadow: 5px 7px 11px #f9cc0b53;
  background: var(--maincolor2);
  color: #fff;
  font-family: Poppins-Regular;
  cursor: pointer;
}
.ar #contact-section-three form .end button {
  font-family: shabnam-light;
}
#contact-section-three form .end .file {
  display: flex;
  align-items: center;
  cursor: pointer;
}
#contact-section-three form .end .file img {
  width: 28.1px;
  height: 20.72px;
  margin-right: 10px;
}
.ar #contact-section-three form .end .file img {
  margin-left: 10px;
}

/* resposive */

@media only screen and (max-width: 1400px) {
  #contact-section-two .all .grid-item .carts {
    width: unset;
  }
}

@media only screen and (max-width: 992px) {
  #contact-section-one {
    padding: 2rem 0;
  }
  #contact-section-one .all {
    width: 95%;
  }
  #contact-section-two .all {
    width: 85%;
    grid-template-columns: auto auto;
  }
  #contact-section-two .all .grid-item {
    justify-content: start;
    margin-bottom: 10px;
  }
  #contact-section-two .all .grid-item:nth-child(1) {
    order: 3;
  }
  #contact-section-three {
    padding: 2rem 0;
  }
  #contact-section-three form {
    width: 85%;
  }
}
@media only screen and (max-width: 768px) {
  #contact-section-one {
    display: none;
  }
  #contact-section-two {
    margin-top: unset;
  }
  #contact-section-two .all {
    grid-template-columns: auto;
    width: 90%;
    justify-content: unset;
  }
  #contact-section-three form {
    width: 90%;
    border: unset;
    padding: 2rem 0;
    background: unset !important;
    border: 0 !important;
  }
  #contact-section-three form .end {
    padding: unset;
  }
  #contact-section-three form .end button {
    width: 100%;
  }
  #contact-section-three form .message {
    padding: unset;
    padding: 0 1rem;
  }
  #contact-section-three form .end .file {
    margin-top: 25px;
  }
  #contact-section-three form .inputs div {
    padding: unset;
  }
  #contact-section-two .all .grid-item .carts {
    border: unset;
    width: 100%;
    border-radius: unset;
    background: unset !important;
    border: 0 !important;
    border-bottom: 1px solid var(--maincolorborder3) !important;
  }
  #contact-section-three form .inputs div input {
    margin-bottom: 15px;
  }
  #contact-section-three form .inputs {
    grid-template-columns: auto;
    padding: 2rem 1rem;
  }
  #contact-section-three form .end {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }
  #contact-section-three form .end button {
    order: 2;
  }
  #contact-section-three form .end .file {
    order: 1;
    padding-bottom: 25px;
  }
}
