#carts-warning{
    position: absolute;
    bottom: 3rem;
    right: 0;
    width: 347px;
    border-radius: 20px 0 0 20px;
    display: flex;
    box-shadow: 0 3px 25px #00000010;
}
#dark #carts-warning{
    box-shadow: 0 3px 25px #ffffff15;
}
#carts-warning .right{
    width: 80%;
    border-left: 4px solid #EAB04C;
    padding: 5px 1rem;
}
#carts-warning .right .title{
    font-family: shabnam-bold;
}
#dark #carts-warning .right .title{
    color: #fff;
}
#carts-warning .right .des{
    font-size: 14px;
    font-family: shabnam-light;
    color: #707070;
}
#carts-warning .left{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(270deg,#EAB04C50,#EAB04C00);
}



@media only screen and (max-width: 1024px) {
    #carts-warning{
        bottom: 5rem;
    }  
}