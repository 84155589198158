#light .mobile_nav{
    background: white;
}
#dark .mobile_nav{
   background: black;
}
/* //////////////////////////////////////////// */
#light .mobile_nav>section> span{
    background: white;
}
#dark .mobile_nav>section> span{
   background: black;
}
/* //////////////////////////////////////////// */