.grand-parent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    /* border: 1px solid red; */
}
.new-password-parent{
    display: flex;
    flex-wrap: wrap;
    height: 565px;
    /* border: 1px solid red; */
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 1px  1px 5px rgb(223, 220, 220);
}
/* /////////////////// right ///////////////////// */
/* /////////////////// right ///////////////////// */
.new-password-right{
    width: 50%;
    height: 100%;
    padding: 32px 16px;
}
/* form *//* form *//* form */
.new-password-right >form{
    position: relative;
    width: 100%;
    height: 435px;
    /* border: 1px solid red; */
    margin-top: 66px;
}
.new-password-right form h1{
    font-size: 22px;
    margin-bottom: 5px;
}
.new-password-right form p{
    height: 42px;
    font-size: 16px;
    text-align: center;
    color: #707070;
    padding: 0 10px;
    margin-bottom: 20px;
}
/* //mini-parent */
.new-password-right form>div{
    position: relative;
    width: 100%;
    height: 72px;
    /* border: 1px solid black; */
    margin-bottom: 15px;
}

.new-password-right form>div label{
    display: flex;
    height: 19px;
    font-size: 14px;
    color:#a5a5a5 ;
    margin-bottom: 7px;
}
.new-password-right form>div input{
    width: 100%;
    height: 46px;
    border-radius: 10px;
    border: 1px solid #e3e4f5;
}
/* eye *//* eye *//* eye */

.new-password-right form>div span{
    position: absolute;
    bottom: 0px;
    display: flex;
    height: 46px;
    align-items: center;
    /* background: red; */
}
/* submit-form */
.new-password-right form .submit-form{
    width: 100%;
    height: 46px !important;
}
.new-password-right form .submit-form button{
    width: 100%;
    height: 100%;
    background:#f9cc0b ;
    color: white;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
}
/* absolute *//* absolute *//* absolute */
.new-password-right form >.recently{
    position: absolute;
    bottom: 0px ; right: 0;
    margin-bottom: 0 !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 51px !important;
    font-size: 14px;
    border-top: 1px solid #e3e4f5;
}
.new-password-right .recently span a{
    color: #f6b33f;
    font-size: 14px;
}





/* responsive ریسپانسیو */

/* Max 640 */
@media screen and (max-width: 640px) {
    body{
       /* background: palevioletred; */
    }
     .new-password-parent{
        align-content: flex-start;
        width: 90%;
        justify-content: center;
        height: 506px;
        /* border: 1px solid black; */
        border-radius: 20px;
     }
     
  
    
    
     .new-password-right{
        width: 90%;
        height: 310px;
        order: 1;
        /* border: 1px solid orange; */
        padding: 0;
    }
    .new-password-right form{
        margin-top: 0;
    }
    .new-password-right form .recently{
        position: fixed;
        width: 90%;
        right: 5%;
        bottom: 20px;
        /* border: 1px solid red; */
    }
  
}








/* Min 640 */
@media screen and (min-width: 640px) {
   body{
       /* background: rgb(112, 216, 176); */
    }
   .new-password-parent{
     width: 90% !important;
     max-width: 704px !important;
   }
}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 1024px) {
   body{
       /* background: rgb(206, 164, 86); */
    }
    .new-password-parent{
       width: 842px !important;
       max-width: 842px !important;
     }
}
@media screen and (min-width: 1280px) {

}
@media screen and (min-width: 1536px) {

}




