#xchange{
    position: relative;
    width: 100%;
    min-height: 100vh;
    
    /* border: 1px solid green; */
}
#xchange >main{
    min-height: 100vh;
    padding: 10px 30px;
    /* border: 1px solid red; */
}
/* ////////////////////////////////////////////// */
/* ////////////////////////////////////////////// */
#xchange .Change_Send_Receive{
    width: 100%;
    height: 660px;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 100px;
    /* border: 1px solid purple; */
    background-color: #FFF;
}
#dark #xchange .Change_Send_Receive {
    background-color: #000;
}
#xchange .Change_Send_Receive .three_button>button{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 116px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #edeef1;
   
}
#dark #xchange .Change_Send_Receive .three_button>button {
    color: #fff;
}
/* ///////////////////باکس ارسال////////////////////// */
/* ///////////////////باکس ارسال//////////////////// / */
#xchange .send-box{
    border: 1px solid #edeef1;
    box-shadow: 1px 1px 5px rgb(207, 204, 204);
    padding: 10px 15px;
    
}
#xchange .send-box>div{
    /* border: 1px solid black; */
}
#xchange .send-box .network_type{
    /* background: olive; */
}
#xchange .network_type>section{
    display: flex;
    align-items: center;
    column-gap: 10px;
    border: 1px solid #edeef1;
    border-radius: 5px;
    padding: 0 10px;
}
#xchange .network_type>section .ballet{
   display: block;
   width: 23px;height: 23px;
   border-radius: 50%;
   background: #f6b33f;
}

/* ///////////////////باکس دریافت////////////////////// */
/* ///////////////////باکس دریافت//////////////////// / */
#xchange .receive-box{
    border: 1px solid #edeef1;
    box-shadow: 1px 1px 5px rgb(207, 204, 204);
    padding: 10px 15px;
}
#xchange .receive-box>div{
    /* border: 1px solid black; */
}



/* ///////////////ریسپانسیو///////////////// */
/* ///////////////ریسپانسیو///////////////// */
/* ///////////////ریسپانسیو///////////////// */

/* max 640     mobile-mobile */
@media screen and (max-width:640px) {
    #xchange >main{
       width: 100%;
        padding: 10px 15px;
    }
    #xchange >main .receive-box{
        height: auto;
    }
    #xchange .Change_Send_Receive{
        height: auto;
    }
    #xchange >main .receive-box img{
        /* width: 300px;height: 300px; */
        /* border: 1px solid green; */
    }
}

/* min 640 sm-sm-sm-sm-sm*/
@media screen and (min-width:640px) {
    #xchange >main{
        width: 100%;
         padding: 10px 15px;
     }
}
/* min 768 md-md-md-md-md*/
@media screen and (min-width:768px) {
    
}
/* min 1024 lg-lg-lg-lg-lg*/
@media screen and (min-width:1024px) {
    #xchange >main{
        width: 82%;
         padding: 10px 15px;
     }
}
/* min 1280   xl-xl-xl-xl*/
@media screen and (min-width:1280px) {
}
/* min 1536   2xl-2xl-2xl-2xl*/
@media screen and (min-width:1536px) {
   
}