#light .convert-box{
    background: white;
}
#dark .convert-box{
   background: black;
}
/* ///////////////////////////////////////////////// */
#light .convert-box .two_btn{
    color: black;
}
#dark .convert-box .two_btn{
   color: white;
}
/* ///////////////////////////////////////////////// */
#light .option_style{
    color: black;
}
#dark .option_style{
   color: white;
}

