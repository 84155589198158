/* Top-section */
.css-13cymwt-control {
  background-color: unset !important;
}
#top-section {
  width: 100%;
  padding: 2rem 0;
  transform: translateY(0px);
  margin-top: -250px;
}
#top-section .all {
  margin: auto;
  width: 60%;
  padding: 2rem 2rem 2rem 0;
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 50px #00000016;
}
.ar #top-section .all {
  padding: .5rem 0 .5rem 2rem;
}
#dark #top-section .all {
  background: #000;
  box-shadow: 0 3px 50px #ffffff34;
}
#top-section .all .left {
  width: 40%;
  height: 100%;
  transform: scaleX(-1);
  display: flex;
  align-items: center;
  justify-content: end;
}
.ar #top-section .all .left {
  transform: unset;
  justify-content: start;
}
#top-section .all .left img {
  width: 334px;
  margin-top: 20px;
}
#top-section .all .right {
  position: relative;
  width: 100%;
  padding-top: 4rem;
}
#top-section .all .right .convert-box{
  height: unset;
}
#top-section .all .right .convert-box .two_btn{
  display: none;
}
#top-section .all .right .convert-box form button{
  display: none;
}
#top-section .all .right .pay {
  margin-bottom: 13px;
}
#top-section .all .right .pay .title {
  padding-bottom: 9px;
  padding-left: 10px;
  color: var(--maincolorborder1);
}
#top-section .all .right .pay .input {
  width: 100%;
}
#top-section .all .right .pay .input input {
  height: 46px;
  /* border: 1px solid var(--maincolorborder2); */
  width: 50%;
  padding: 10px;
}
#top-section .all .right .pay .titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#top-section .all .right .pay .title2 {
  padding-bottom: 9px;
  padding-right: 10px;
  color: var(--maincolorborder1);
  font-size: 14px;
}
#top-section .all .right .pay .input input:first-child {
  border-radius: 10px 0 0 10px;
}
.ar #top-section .all .right .pay .input input:first-child {
  border-radius: 0 10px 10px 0;
}
#dark #top-section .all .right .pay .input input {
  background: #000;
}
#top-section .all .right .pay .input input:last-child {
  border-radius: 0 10px 10px 0;
}
.ar #top-section .all .right .pay .input input:last-child {
  border-radius: 10px 0 0 10px;
}
#top-section .all .right .bitcoin-address {
  width: 100%;
  margin-bottom: 13px;
  display: flex;
  gap: 13px;
}
#top-section .all .right .bitcoin-address>div {
  flex-grow: 1;
}
#top-section .all .right .bitcoin-address .title {
  padding-bottom: 9px;
  padding-left: 10px;
  color: var(--maincolorborder1);
  font-size: 14px;
}
#top-section .all .right .bitcoin-address input {
  width: 100%;
  height: 46px;
  /* border: 1px solid var(--maincolorborder2); */
  padding: 10px;
  border-radius: 10px;
}
#dark #top-section .all .right .bitcoin-address input {
  background: #000;
}
#top-section .all .right .end {
  width: 100%;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
}
#top-section .all .right .bt {
  display: flex;
  align-items: center;
  border: 0;
}
#top-section .all .right .bt .w-full.h-full {
  display: flex;
  align-items: self-end;
}
#top-section .all .right .bt .css-13cymwt-control .option_style {
  display: flex;
  align-items: baseline;
}
#top-section .all .right .end .done {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#top-section .all .right .end .title {
  padding-bottom: 9px;
  padding-left: 10px;
  color: var(--maincolorborder1);
  font-size: 14px;
}
#top-section .all .right .end input {
  width: 49%;
  height: 46px;
  /* border: 1px solid var(--maincolorborder2); */
  padding: 10px;
  /* border-radius: 10px 0 0 10px; */
  border-radius: 10px;
}
.ar #top-section .all .right .end input {
  /* border-radius: 0 10px 10px 0; */
  border-radius: 10px;
}
#top-section .all .right .end .css-b62m3t-container {
  width: 49%;
  height: 46px;
  border-radius: 0 10px 10px 0;
}
.ar #top-section .all .right .end .css-b62m3t-container {
  border-radius: 10px 0 0 10px;
}
#top-section .all .right .end .css-13cymwt-control {
  height: 46px;
  border: 1px solid var(--maincolorborder2);
  border-radius: 0 10px 10px 0;
}
.ar #top-section .all .right .end .css-13cymwt-control {
  border-radius: 10px 0 0 10px;
}
#top-section .all .right .end .css-13cymwt-control .option_style {
  display: flex;
  align-items: baseline;
}
#top-section .all .right .end .css-13cymwt-control .option_style span {
  padding-left: 15px;
}
#top-section .all .right .end .css-1fdsijx-ValueContainer {
  height: 45px;
}
#dark #top-section .all .right .end input {
  background: #000;
}
#top-section .all .right .end button {
  width: 49%;
  height: 46px;
  border: 1px solid var(--maincolorborder2);
  background: var(--maincolor2);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-family: Poppins-Bold;
  /* box-shadow: 5px 7px 11px #f9cd0b53; */
}
.ar #top-section .all .right .end button {
  font-family: Shabnam-Bold;
}
#dark #top-section .all .right .end button {
  color: #000;
}
#top-section .all .right .text {
  text-align: center;
  font-size: 14px;
  font-family: Poppins-Regular;
  padding-top: 15px;
  color: var(--maincolorborder1);
}
.ar #top-section .all .right .text {
  text-align: center;
  font-size: 14px;
  font-family: Shabnam-Light;
  padding-top: 15px;
  color: var(--maincolorborder1);
}
#top-section .all .right .text span {
  color: #000;
}
#dark #top-section .all .right .text span {
  color: #fff;
}
/* section-one */
#section-one {
  width: 100%;
  height: 421px;
  background: linear-gradient(#2a303a, #3a2d16);
  position: relative;
}
#section-one .background {
  width: 100%;
  height: 100%;
  position: absolute;
}
#section-one .title {
  color: #fff;
  font-family: Poppins-Regular;
  font-size: 18px;
  width: 80%;
  margin: auto;
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ar #section-one .title {
  font-family: Shabnam-Light;
}
#section-one .text-left {
  width: 40%;
  text-align: left;
}
.ar #section-one .text-left {
  text-align: right;
  font-size: 22px;
}
#section-one .title .text-left .sec {
  font-family: Poppins-Bold;
  font-size: 24px;
}
.ar #section-one .title .text-left .sec {
  font-family: Shabnam-Bold;
  font-size: 36px;
}
#section-one .title .text-left .sec span {
  color: var(--maincolor2);
}
#section-one .text-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#section-one .text-right .frs {
  width: fit-content;
  padding: 10px;
  background: var(--maincolor3);
  font-size: 16px;
  font-family: Poppins-Regular;
}
.ar #section-one .text-right .frs {
  font-family: Shabnam-Light;
}
#section-one .text-right .sec {
  width: fit-content;
  padding: 10px;
  background: #fff;
  font-size: 16px;
  font-family: Poppins-Regular;
  color: #000;
  transform: rotate(-2deg) translateY(4px);
  margin-top: 2px;
}
.ar #section-one .text-right .sec {
  font-family: Shabnam-Light;
  transform: rotate(2deg) translateY(2px);
}
/* section-two */
#section-two {
  padding: 4rem 0;
}
#section-two .all {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
}
#section-two .all .grid-item {
  padding: 1rem;
}
#section-two .all .grid-item .carts {
  padding: 2rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: Poppins-Regular;
  box-shadow: 1px 1px 20px #0000001b;
  border-radius: 25px;
}
.ar #section-two .all .grid-item .carts {
  font-family: Shabnam-Light;
}
#dark #section-two .all .grid-item .carts {
  box-shadow: 1px 1px 20px #ffffff53;
}
#section-two .all .grid-item .carts .image {
  width: 120px;
  height: 120px;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../image/currency/Bitcoin.png);
  background-size: cover;
  filter: blur(40px);
}
#section-two .all .grid-item .carts .image1 {
  width: 120px;
  height: 120px;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../image/currency/Etherium.png);
  background-size: cover;
  filter: blur(40px);
}
#section-two .all .grid-item .carts .image2 {
  width: 120px;
  height: 120px;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../image/currency/shib.png);
  background-size: cover;
  filter: blur(40px);
}
#section-two .all .grid-item .carts .image3 {
  width: 120px;
  height: 120px;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../image/currency/Tether.png);
  background-size: cover;
  filter: blur(40px);
}
#section-two .all .grid-item .carts .logo {
  width: 59px;
  height: 59px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
#section-two .all .grid-item .carts .details {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#dark #section-two .all .grid-item .carts .details {
  color: #fff;
}
#section-two .all .grid-item .carts .details .up {
  color: #15de5f;
}
#section-two .all .grid-item .carts .details .down {
  color: #f63f3f;
}
#section-two .all .grid-item .carts .time {
  margin-top: -20px;
  margin-bottom: 20px;
  color: var(--maincolorfont1);
  font-size: 14px;
}
/* Section-three */
#section-three {
  padding-left: 4rem 0;
}
#section-three .all {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
#section-three .all .left {
  width: 63%;
}
#section-three .all .left img {
  width: 100%;
  border-radius: 10px;
}
#section-three .all .right {
  width: 33%;
  background: url(../image/home-page/Ellipse.png);
  background-size: cover;
  background-position: top;
}
#dark #section-three .all .right {
  background: unset;
}
#section-three .all .right .text1 {
  font-size: 22px;
  font-family: Poppins-Bold;
  width: 337px;
  padding-bottom: 15px;
}
.ar #section-three .all .right .text1 {
  font-family: Shabnam-Bold;
}
#dark #section-three .all .right .text1 {
  color: #fff;
}
#section-three .all .right .text2 {
  font-size: 22px;
  font-family: Poppins-Bold;
  color: var(--maincolor2);
  padding-bottom: 18px;
}
.ar #section-three .all .right .text2 {
  font-family: Shabnam-Bold;
}
#section-three .all .right .des {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: Poppins-Regular;
  color: var(--maincolorborder1);
}
.ar #section-three .all .right .des {
  font-family: Shabnam-Light;
}
#section-three .all .right ul {
  padding: 0;
  margin: 0;
  margin-top: 21px;
}
#section-three .all .right ul li {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
#section-three .all .right ul li span {
  padding-left: 1rem;
  font-size: 16px;
  font-family: Poppins-Regular;
}
.ar #section-three .all .right ul li span {
  padding-right: 1rem;
  font-family: Shabnam-Light;
}
#dark #section-three .all .right ul li span {
  color: #fff;
}
/* Section-four */
#section-four {
  padding: 2rem 0;
}
#section-four .all {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
}
/* #section-four .all .grid-items {
  padding: 1rem;
} */
#section-four .all .grid-items .carts {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#section-four .all .grid-items .carts img {
  width: 60px;
  padding-bottom: 10px;
}
#section-four .all .grid-items .carts .title {
  padding-bottom: 4px;
  padding-top: 7.8px;
  font-size: 16px;
  font-family: Poppins-Bold;
  text-align: center;
}
.ar #section-four .all .grid-items .carts .title {
  font-family: shabnam-Bold;
}
#dark #section-four .all .grid-items .carts .title {
  color: #fff;
}
#section-four .all .grid-items .carts .des {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: Poppins-Regular;
  color: var(--maincolorfont2);
  text-align: center;
  width: 202px;
}
.ar #section-four .all .grid-items .carts .des {
  font-family: Shabnam-Light;
}
#dark #section-four .all .grid-items .carts .des {
  color: var(--maincolorfontd2);
}
/* Section-five */
#section-five {
  padding: 2rem 0;
}
#section-five .all {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#section-five .all .left {
  width: 31%;
}
#section-five .all .left .t1 {
  color: var(--maincolor2);
  font-family: Poppins-Regular;
  font-size: 16px;
  padding-bottom: 5px;
}
.ar #section-five .all .left .t1 {
  font-family: shabnam-light;
}
#section-five .all .left .t2 {
  font-family: Poppins-Bold;
  font-size: 22px;
  padding-bottom: 16px;
  padding-left: 20px;
}
.ar #section-five .all .left .t2 {
  font-family: shabnam-Bold;
  padding-right: 20px;
}
#section-five .all .left .t3 {
  font-family: Poppins-Regular;
  font-size: 16px;
  padding-bottom: 28px;
  color: var(--maincolorborder1);
}
.ar #section-five .all .left .t3 {
  font-family: shabnam-light;
}
#section-five .titles {
  text-align: center;
  width: 350px;
  margin: auto;
  display: none;
}
#section-five .titles .t1 {
  color: var(--maincolor2);
  font-family: Poppins-Regular;
  font-size: 16px;
  padding-bottom: 5px;
}
.ar #section-five .titles .t1 {
  font-family: shabnam-light;
}
#section-five .titles .t2 {
  font-family: Poppins-Bold;
  font-size: 22px;
  padding-bottom: 16px;
  padding-left: 20px;
}
.ar #section-five .titles .t2 {
  font-family: shabnam-Bold;
  padding-right: 20px;
}
#dark #section-five .titles .t2 {
  color: #fff;
}
#dark #section-five .all .left .t2 {
  color: #fff;
}
#section-five .titles .t3 {
  font-family: Poppins-Regular;
  font-size: 16px;
  padding-bottom: 28px;
  color: var(--maincolorborder1);
}
#section-five .titles .t3 {
  font-family: shabnam-light;
}
#section-five .all .left .box {
  display: flex;
  flex-direction: column;
  margin-bottom: 28.3px;
}
#dark #section-five .all .left .box {
  color: #fff;
}
#section-five .all .left .box .top {
  display: flex;
  text-align: center;
  font-family: Poppins-Bold;
  font-size: 16px;
}
#section-five .all .left .box .top {
  font-family: shabnam-Bold;
}
#section-five .all .left .box .top img {
  padding-right: 12px;
}
.ar #section-five .all .left .box .top img {
  padding-left: 12px;
}
#section-five .all .left .box p {
  margin-top: 5px;
  color: var(--maincolorborder1);
}
#section-five .all .right {
  width: 58%;
  display: flex;
  justify-content: center;
  position: relative;
}
#section-five .all .right .text {
  position: absolute;
  bottom: 55px;
  font-family: Poppins-Bold;
  font-size: 20px;
}
.ar #section-five .all .right .text {
  font-family: Shabnam-Bold;
}
/* Section-blog */
#section-blog {
  padding: 4rem 0;
}
#section-blog .all {
  width: 100%;
  margin: auto;
}
#section-blog .title {
  text-align: center;
  font-size: 24px;
  font-family: Poppins-Bold;
}
.ar #section-blog .title {
  font-family: shabnam-Bold;
}
#dark #section-blog .title {
  color: #fff;
}
#section-blog .des {
  text-align: center;
  font-size: 16px;
  font-family: Poppins-Regular;
  padding-bottom: 19px;
}
.ar #section-blog .des {
  font-family: Shabnam-Light;
}
#dark #section-blog .des {
  color: #fff;
}
#section-blog .all .swiper {
  width: 100%;
  height: 100%;
}
#section-blog .all a {
  text-align: right;
  text-decoration: none;
  color: var(--maincolor3);
  display: flex;
  align-items: center;
}
.ar #section-blog .all a {
  text-align: left;
}
#section-blog .all .link {
  text-align: right;
  margin-right: 15%;
  margin-top: -35px;
  padding-bottom: 35px;
  display: flex;
  justify-content: end;
}
.ar #section-blog .all .link {
  text-align: left;
  margin-left: 15%;
  margin-top: -35px;
  padding-bottom: 35px;
}
#section-blog .all .link a svg {
  fill: var(--maincolor3);
  margin-left: 5px;
}
.ar #section-blog .all .link a svg {
  margin-right: 5px;
  transform: scaleX(-1);
}
#section-blog .all .swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 25px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
#section-blog .all .swiper .swiper-wrapper {
  padding-bottom: 45px;
}
#section-blog .all .swiper-button-next {
  transform: translateY(105px) translateX(-205px);
  z-index: 5;
}
#section-blog .all .swiper-button-prev {
  transform: translateY(105px) translateX(205px);
  z-index: 5;
}
#section-blog
  .all
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  z-index: 1;
}
#section-blog .all .swiper .swiper-slide .carts .text {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: var(--maincolor3);
  border-radius: 0 0 15px 15px;
  font-size: 14px;
  height: 67px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 8px;
  color: #fff;
  opacity: 0.77;
  font-family: Poppins-Bold;
}
#section-blog .all .swiper .swiper-slide .carts .text .time {
  width: 100px;
  font-family: Poppins-Regular;
}
.ar #section-blog .all .swiper .swiper-slide .carts .text .time {
  width: 100px;
  font-family: Shabnam-Light;
}
#section-blog .all .swiper .swiper-slide .carts .text .title {
  font-size: 14px;
  text-align: left;
}
#section-blog .all .swiper .swiper-slide .carts .text .title {
  text-align: right;
}
#section-blog .all .swiper .swiper-slide .carts {
  width: 540px;
  height: 248px;
  border-radius: 20px;
  overflow: hidden;
}
#section-blog .all .swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#section-blog .all .swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 16px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 3px)
  );
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 5px);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
#dark #section-blog .all .swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 16px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 3px)
  );
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 5px);
  background: var(--swiper-pagination-bullet-inactive-color, #fff);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
#section-blog .all .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--maincolor2);
}
#dark #section-blog .all .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--maincolor2);
}
#section-blog .all .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: url(../image/home-page/Icon\ awesome-arrow-alt-circle-right.png);
}
#section-blog .all .swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
#section-blog .all .swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after {
  content: url(../image/home-page/Icon\ awesome-arrow-alt-circle-right.png);
  transform: scale(-1);
}

/* responsive */
@media only screen and (max-width: 1400px) {
  #section-blog .all .swiper-button-next {
    transform: translateY(75px) translateX(-205px);
    z-index: 5;
  }
  #section-blog .all .swiper-button-prev {
    transform: translateY(75px) translateX(205px);
    z-index: 5;
  }
  #section-blog .all .swiper .swiper-slide .carts .text {
    border-radius: 0 0 12px 12px;
  }
}
@media only screen and (max-width: 1200px) {
  #section-blog .all .swiper .swiper-slide .carts .text {
    border-radius: 0 0 11px 11px;
  }
  #section-one .text-right .frs {
    width: 296px;
  }
  #section-one .text-right .sec {
    width: 357px;
  }
  #section-blog .all .swiper-button-next {
    transform: translateY(60px) translateX(-205px);
    z-index: 5;
  }
  #section-blog .all .swiper-button-prev {
    transform: translateY(65px) translateX(205px);
    z-index: 5;
  }
  #top-section .all{
    width: 80%;
  }
}
@media only screen and (max-width: 992px) {

  .ar #top-section .all {
    padding: 2rem 2rem 2rem 0;
}
  #section-four .all .grid-items .carts .des {
    width: 156px;
  }
  #section-blog .des {
    width: 349px;
    margin: auto;
  }
  #section-blog .all .link {
    margin-right: 5%;
    margin-top: -45px;
  }
  #section-blog .all .swiper .swiper-slide .carts .text {
    border-radius: 0 0 9px 9px;
  }
  html,
  body {
    overflow-x: hidden;
  }
  #top-section .all {
    width: 85%;
    flex-direction: row-reverse;
    padding: 2rem 0 2rem 2rem;
  }

  #section-one .title {
    width: 85%;
  }
  #top-section .all .left {
    transform: scaleX(1);
    justify-content: safe;
  }
  .ar #top-section .all .left {
    transform: scaleX(-1);
    justify-content: safe;
}
  #section-one .text-left {
    width: 45%;
    justify-content: end;
  }
  #section-one .title .text-left .sec {
    text-align: left;
  }
  #section-one .text-right .frs {
    font-size: 15px;
    width: 276px;
  }
  #section-one .text-right .sec {
    font-size: 15px;
    width: 337px;
  }
  #section-two .all {
    width: 85%;
    grid-template-columns: auto auto;
  }
  #section-three .all {
    width: 85%;
    flex-direction: column;
  }
  #section-three .all .left {
    width: 100%;
  }
  #section-three .all .right {
    width: 100%;
  }
  #section-three .all .right .details {
    display: flex;
  }
  #section-four .all {
    width: 85%;
  }
  #section-four .all .grid-items .carts {
    text-align: center;
  }
  #section-five .all {
    width: 85%;
  }
  #section-five .all .left .top {
    display: none;
  }
  #section-five .titles {
    display: block;
  }
  #section-blog .all .swiper .swiper-slide .carts .text .time {
    display: none;
  }
  #section-blog .all .swiper .swiper-slide .carts .text {
    height: 56px;
  }
}
@media only screen and (max-width: 768px) {
  #section-five .all .right .text {
    bottom: 35px;
  }
  .ar #section-blog .all .link {
    justify-content: start;
  }
  .ar #section-one .text-left {
    text-align: center;
  }
  .ar #section-one .title .text-left .sec {
    text-align: center;
  }
  .ar #top-section .all .left {
    /* transform: rotate(90deg); */
  }
  #top-section .all .right .end .css-b62m3t-container {
    margin-top: -10px;
  }
  .pio {
    width: 50% !important;
  }
  #top-section {
    margin-top: -150px;
  }
  #top-section .all {
    padding: 1.5rem 1.5rem 0 1.5rem;
    /* padding-right: 0; */
    width: 90%;
    /* height: 665px; */
    /* overflow: hidden; */
    flex-direction: column;
    position: relative;
    padding-bottom: 360px;
  }
  .ar #top-section .all {
    padding: 1.5rem;
    /* height: 650px; */
  }
  #top-section .all .left {
    order: 5;
    width: 100%;
    transform: rotate(90deg);
    position: absolute;
    justify-content: unset;
    /* margin-top: -25px; */
    /* height: 436px; */
    bottom: -300px;
  }
  #top-section .all .left img {
    width: 100%;
    margin-top: 0;
    display: none;
  }
  #top-section .all .right .end .done {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  #top-section .all .right .end input {
    width: 100%;
    margin-bottom: 10px;
  }
  #top-section .all .right .end button {
    margin-top: 1.5rem;
    width: 100%;
  }
  #section-one .title {
    flex-direction: column;
    padding: 1rem 0;
  }
  #section-one .text-left {
    width: 100%;
  }
  #section-one .text-right {
    margin-top: 30px;
    width: 100%;
  }
  #section-blog {
    padding: 1rem 0;
  }
  #section-blog .all .swiper-button-next {
    transform: translateY(50px) translateX(-25px);
    z-index: 5;
  }
  #section-blog .all .swiper .swiper-slide .carts .text {
    height: 35px;
  }
  #section-blog .all .swiper-button-prev {
    transform: translateY(50px) translateX(25px);
    z-index: 5;
  }
  #section-blog .all .swiper .swiper-slide .carts .text .title {
    height: 25px;
    overflow: scroll;
  }
  #section-blog .all .link {
    margin-top: -25px;
  }
  #section-blog .all .swiper {
    padding: 0 0;
  }
  #section-two .all {
    width: 85%;
    grid-template-columns: auto;
  }
  #section-three .all .right {
    order: 1;
  }
  #section-three .all .left {
    order: 2;
  }
  #section-three .all .right .details {
    flex-direction: column;
  }
  #section-four .all {
    grid-template-columns: auto auto;
  }
  #section-four .all .grid-items .carts {
    margin-bottom: 38px;
  }
  #section-five .all .left {
    width: 100%;
    order: 2;
  }
  #section-five .all .right {
    width: 100%;
    order: 1;
  }
  #section-five .all .right img {
    width: 100%;
  }
  #section-five .all .left .top {
    display: block;
  }
  #section-five .titles {
    display: none;
  }
  #section-five .all {
    display: flex;
    flex-direction: column;
  }
  #section-blog .title {
    text-align: left;
    padding-left: 15px;
  }
  #section-blog .all .swiper .swiper-slide .carts{
    width: unset;
    height: unset;
  }
  #section-blog .des {
    display: none;
  }
  #section-five {
    padding: 1rem 0;
  }

  #top-section .all .right .bitcoin-address {
    flex-direction: column;
  }
}


.home-convert_box-input {
  direction: ltr;
}
.home-convert_box-input::placeholder {
  color: #ccc;
  font-size: 14px;
  font-weight: light;
}