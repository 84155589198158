/* helpcenter2-section-two */
#helpcenter2-section-two {
  padding: 4rem 0;
}
#helpcenter2-section-two .all {
  width: 50%;
  margin: auto;
}
#helpcenter2-section-two .all .item {
  width: 100%;
  padding: 1rem;
  font-size: 14px;
  font-family: Poppins-Regular;
}
.ar #helpcenter2-section-two .all .item {
  font-family: shabnam-light;
}
#dark #helpcenter2-section-two .all .item {
  color: #fff;
}
#helpcenter2-section-two .all .item .title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
#helpcenter2-section-two .all .item .title .negetive {
  display: none;
}
#helpcenter2-section-two .all .item .content {
  max-height: 0;
  overflow: hidden;
  transition: 1s all ease;
  opacity: 0;
  padding: 1rem;
}
#helpcenter2-section-two .all .item .show {
  max-height: 1000px;
  transition: 1s all ease;
  opacity: 1;
}

/* responsive */
@media only screen and (max-width: 992px) {
  #helpcenter2-section-two .all {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
    #helpcenter2-section-two .all {
      width: 90%;
    }
  }
  