@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Poppins-Bold;
  src: url(../public/fonts/Poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: Poppins-Medium;
  src: url(../public/fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../public/fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Shabnam;
  src: url(../public/fonts/Shabnam/Shabnam.ttf);
}
@font-face {
  font-family: Shabnam-Bold;
  src: url(../public/fonts/Shabnam/Shabnam-Bold.ttf);
}
@font-face {
  font-family: Shabnam-Light;
  src: url(../public/fonts/Shabnam/Shabnam-Light.ttf);
}
@font-face {
  font-family: Shabnam-FD;
  src: url(../public/fonts/Shabnam/Shabnam-FD.ttf);
}
@font-face {
  font-family: Shabnam-Light-FD;
  src: url(../public/fonts/Shabnam/Shabnam-Light-FD.ttf);
}
.lang-english html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Poppins-Regular;
  scroll-behavior: smooth;
}
.lang-farsi html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Shabnam;
  scroll-behavior: smooth;
}
.en {
  font-family: Poppins-Regular;
}
.ar {
  font-family: Shabnam;
}

/* مهدی *//* مهدی *//* مهدی *//* مهدی *//* مهدی */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  width: 100%;
  min-height: 100vh;
  /* font-family: IRANSans ; */
  overflow-x: hidden;
}
  
a{
  text-decoration: none !important;
}
select,button,input{
  border: none;
  outline: none;
}
.flexCenter{
  @apply flex items-center justify-center content-center
}
.flexStart{
  @apply flex items-center justify-start
}
.flexBetween{
  @apply flex items-center justify-between
}

.Toastify__toast-body {
  font-family:Poppins-Regular !important ;
  font-size: 14px;
}

.Toastify * {
  font-family: Shabnam;
}