#register{
    /* border: 2px solid red; */
}
.grand-parent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    /* border: 1px solid red; */
}
.register-parent{
    display: flex;
    flex-wrap: wrap !important;
    height: 565px;
    /* border: 1px solid red; */
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 1px 1px 5px rgb(193, 190, 190);
}
/* right *//* right */
/* right *//* right */
/* right *//* right */

.register-right {
    width: 50%;
    height: 100%;
    
    /* border: 1px solid red; */
    padding: 14px;
}
.register-right h1{
    font-size: 22px;
    font-weight: bolder;
    text-align: center;
    /* border: 1px solid red; */
    margin-bottom: 5px;
}
.register-right form{
    position: relative;
    width: 100%;
    height: 497px;
    /* border: 1px solid red; */
}
.register-right form .mini-parent{
    position: relative;
    width: 100%;
    height: 72px;
    /* border: 1px solid black; */
    margin-bottom: 15px;
}
.register-right form  label{
    display: flex;
    height: 19px;
    font-size: 14px;
    color:#a5a5a5 ;
    margin-bottom: 7px;
    user-select: none;
}
.register-right form .mini-parent input{
    /* width: 100%; */
    height: 46px;
    border-radius: 10px;
    border: 1px solid #e3e4f5;
    padding: 10px;
}
.register-right form .mini-parent span.toggleEye{
    position: absolute;
    display: flex;
    height: 46px;
    align-items: center;
    /* background: rgb(181, 144, 144); */
    bottom: 0px;
    cursor: pointer;
}
.register-right form .mini-parent span.toggleEye svg{
    font-size: 25px;
}
   
/* section *//* section */
/* section *//* section */
.register-right form >section{
    display: flex;
    align-items: center;
    height: 28px;
    /* border: 1px solid red; */
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
}
.register-right form >section span{
    
}
.register-right form >section input{
    
}
/* button submit *//* button submit */
/* button submit */
.register-right form .submit-form{
    width: 100%;
    height: 46px !important;
}
.register-right form .submit-form button{
    width: 100%;
    height: 100%;
    /* transition: .4s; */
    color: white;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
}
/* recently *//* recently */
/* recently *//* recently */
.register-right form > .recently{
    position: absolute;
    bottom: 0px ; right: 0;
    margin-bottom: 0 !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 46px !important;
    font-size: 14px;
    border-top: 1px solid #e3e4f5;
    /* border: 1px solid #e3e4f5; */
}
.register-right .recently span a{
    color: #f6b33f;
}

#register .option_style{
    direction: ltr;
    display: flex !important;
    justify-content: flex-start;
    column-gap: 10px;
    height: 100%;
    font-size: 12px;
    /* background: gray; */
    
}
#register .option_style span{
   /* background: red; */
}











/* responsive ریسپانسیو */
/* responsive ریسپانسیو */

/* MAX 640 */
@media screen and (max-width: 640px) {
     body{
        /* background: rgb(228, 136, 177); */
     }
     .register-parent{
         width: 90%;
         height: 620px;
         align-content: flex-start;
        justify-content: center;
        /* border: 1px solid black; */
        border-radius: 20px;
     }
     
  
    
    
     .register-right{
        width: 90%;
        height: auto;
        order: 1;
        padding: 0;
    }
   
    .register-right form .recently{
        position: fixed;
        width: 90%;
        right: 5%;
        bottom: 20px;
        /* border: 1px solid red; */
    }
   
   
  




}
/* MIN 640 */
@media screen and (min-width: 640px) {
    body{
        /* background: rgb(112, 216, 176); */
     }
     .register-parent{
      width: 90% !important;
      max-width: 704px !important;
    }
}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 1024px) {
    body{
        /* background: rgb(206, 164, 86); */
     }
     .register-parent{
        width: 842px !important;
        max-width: 842px !important;
      }
}
@media screen and (min-width: 1280px) {

}
@media screen and (min-width: 1536px) {

}

