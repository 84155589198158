.desktop-nav{
    border-bottom: 1px solid #e6e8f2;
    /* border: 1px solid red; */
}
/* اعلانات *//* اعلانات *//* اعلانات *//* اعلانات */
/* اعلانات *//* اعلانات *//* اعلانات *//* اعلانات */
.desktop-nav .notifications {
    position: relative;
    /* border: 1px solid red; */
}
    
    
.desktop-nav .notifications>span{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}
.desktop-nav ul.notification-list{
    position: absolute; z-index: 500;
    top: 43px;right: -50px;
    width: 413px;height: 500px;
    padding: 22px 16px;
    
    /* border: 1px solid red; */
    overflow-y: scroll;
    border-radius: 20px;
    box-shadow: 1px 1px 5px rgb(212, 208, 208);
}
.desktop-nav ul.notification-list>li{
    min-height: 105px;
    /* border: 1px solid blue; */
    margin-bottom: 10px;
    border-bottom: 1px solid #e3e4e8;
}
.desktop-nav ul.notification-list>li button{
    
    border: 1px solid #e3e4e8;
    
    padding: 5px 7px;
    border-radius: 5px;
    font-size: 14px;
}




/* سه نقطه *//* سه نقطه *//* سه نقطه */
/* سه نقطه *//* سه نقطه *//* سه نقطه */
.desktop-nav .three_dots{
    position: relative;
    /* border: 1px solid red; */
    cursor: pointer;
}
.desktop-nav ul.list_three_dots{
    position: absolute;
    top: 42px;right: -40px;
    width: 245px;
    height: 470px;
    border-radius: 20px;
    /* border: 1px solid black; */
    z-index: 20;
    padding: 26px ;
    box-shadow: 1px 1px 5px gray;
}

.desktop-nav ul.list_three_dots  li{
    
  height: 47px;
  border-bottom: 1px solid #cfcfcf;
  margin-bottom: 10px;
  
}
.desktop-nav ul.list_three_dots a{
    display: flex;
    width: 100%;height: 100%;
    justify-content: space-between;
    align-items: flex-start;
}
/* فلش پایین *//* فلش پایین *//* فلش پایین */
/* فلش پایین *//* فلش پایین *//* فلش پایین */
.desktop-nav .arrow_bottom{
    position: relative;
    /* border: 1px solid green; */
}
.desktop-nav .arrow_bottom ul.profile_menu{
    cursor: auto;
    position: absolute; z-index: 1699;
    top: 22px;
    z-index: 10;
    width: 245px;height: 121px;
    
    box-shadow: 1px 1px 5px rgb(197, 194, 194);
    border-radius: 20px;
    /* border: 1px solid black; */
}
.desktop-nav ul.profile_menu li{
    height: 37.5px;
    /* border: 1px solid black; */
}