#light .main-sidebar {
    background: white;
}

#dark .main-sidebar {
    background: black;
}

/* ////////////////// svg ///////////////////// */
#light .main-sidebar>div:nth-of-type(1)>span svg {
    color: black;
}

#dark .main-sidebar>div:nth-of-type(1)>span svg {
    color: white;
}

/* /////////////////////////////////////// */
#light .main-sidebar .total-wealth>div:nth-of-type(2)>span {
    color: var(--dark-gray-3);
}

#dark .main-sidebar .total-wealth>div:nth-of-type(2)>span {
    color: var(--light-gray-3);
}

/* /////////////////////////////////////// */
