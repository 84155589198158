/* //////////// verification-right ///////////////////*/
#light .verification-right{
    background: white ;
}
#dark .verification-right{
    background: black ;
}
/* //////////////// .back ///////////////////////*/
#light .verification-right .back{
    color: black;
}
#dark .verification-right .back{
   color: white;
}
/* //////////////// h1 ///////////////////////*/
#light .verification-right h1{
    color: black;
}
#dark .verification-right h1{
   color: white;
}
/* //////////////// p ///////////////////////*/
#light .verification-right p{
    color: var(--dark-gray-2);
}
#dark .verification-right p{
    color: var(--light-gray-2);
}



/* //////////////submit button///////////// */
#light .verification-right form .submit-form button{
    background: var(--light-yellow-1);
    color: white;
}
#dark .verification-right form .submit-form button{
    background: var(--dark-yellow-1);
    color: white;
}
/* ////////     recently     //// */
#light .verification-right form  .recently span{
    color: var(--dark-gray-2);
}
#dark .verification-right form  .recently span{
    color: var(--light-gray-2);
}