ul.fixed-mobile-links{
    position: fixed;z-index: 10;
    padding-top: 7px;
    /* border: 1px solid red; */
    bottom: 0 !important;
    box-shadow: 1px -1px 5px rgb(215, 206, 206);
}
ul.fixed-mobile-links >li{
    /* border: 1px solid black; */
    cursor: pointer;
}
ul.fixed-mobile-links >li span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    /* border: 1px solid black; */
}
ul.fixed-mobile-links >li:nth-of-type(2){
    transform: scale(1.3) translateY(-3px);
    position: relative;
}
ul.fixed-mobile-links >li:nth-of-type(2)::before {
    content: '';
    position: absolute;
    bottom: -.8rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
   
    box-shadow: 1px -1px 5px rgb(215, 206, 206);
    z-index: -1;
}
#light ul.fixed-mobile-links >li:nth-of-type(2)::before {
 
    background-color: #fff;
   
}
#dark ul.fixed-mobile-links >li:nth-of-type(2)::before {
 
    background-color: #000000;
   
}




