#page504-section-one {
  width: 100%;
  height: 100%;
  padding: 4rem 0;
}
#page504-section-one .all {
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#dark #page504-section-one .all {
  color: #fff;
}
#page504-section-one .all .left {
  width: 45%;
}
#page504-section-one .all .left img {
  width: 100%;
}
#page504-section-one .all .right {
  width: 45%;
}
#page504-section-one .all .right .t1 {
  color: var(--maincolor2);
  padding-bottom: 20px;
}
#page504-section-one .all .right .t2 {
  font-size: 27px;
  padding-left: 15px;
}
#page504-section-one .all .right .des {
  color: var(--maincolorborder1);
}

@media only screen and (max-width: 992px) {
  #page504-section-one .all {
    width: 60%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
  }
  #page504-section-one .all .left {
    width: 100%;
    margin-bottom: 55px;
  }
  #page504-section-one .all .right {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  #page504-section-one .all {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
  }
}
