/* footer */
footer {
  width: 100%;
  background: #F7F8FA;
  padding-top: 2rem;
  /* margin-top: 115px; */
  border-radius: 3rem 3rem 0 0;
  position: relative;
}
footer i {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 3rem;
  background-color: #fff;
  border-radius: 0 0 2rem 2rem;
}
footer i span {
  position: absolute;
  left: -2rem;
  top: 0;
  background-color: #fff;
  width: 2rem;
  height: 2rem;
}
footer i span::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: #F7F8FA;
  border-radius: 0 2rem 0 0;
}
footer i span:last-child {
  left: unset;
  right: -2rem;
}
footer i span:last-child::before {
  border-radius: 2rem 0 0 0;
}
#dark footer i {
  background-color: #000;
}
#dark footer i span {
  background-color: #000;
}
#dark footer i span::before {
  background-color: #141414;
}
footer .up{
  position: fixed;
  bottom: 15px;
  left: 35px;
  z-index: 5;
}
#dark footer {
  background: #141414;
}
footer .all {
  width: 80%;
  margin: auto;
  position: relative;
  padding-bottom: 1rem;
}
footer .all .logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: -85px;
  z-index: 1;
  width: 160px;
}
footer .all .logo img {
  margin: auto;
  width: 100%;
}
footer .all .all-grid {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 45px;
  margin-bottom: 59px;
}
footer .all .all-grid .list .title {
  font-size: 16px;
  font-family: Poppins-Bold;
}
.ar footer .all .all-grid .list .title {
  font-family: Shabnam-Bold;
}
#dark footer .all .all-grid .list .title {
  color: #fff;
}
footer .all .all-grid .list p {
  margin-top: 14px;
  width: 255px;
  font-size: 16px;
  font-family: Poppins-Regular;
  text-align: justify;
}
.ar footer .all .all-grid .list p {
  font-family: shabnam-light;
}
#dark footer .all .all-grid .list p {
  color: #fff;
}
footer .all .all-grid .list ul {
  margin: 0;
  padding: 0;
  margin-top: 14px;
}
footer .all .all-grid .list ul li {
  list-style: none;
}
footer .all .all-grid .list ul li::before {
  width: 10px;
  height: 10px;
  display: inline-block;
  content: url(./../image/footer/Polygon\ 5.png);
  margin-left: -14px;
  padding-right: 5px;
}
footer .all .all-grid .list ul li a {
  font-size: 16px;
  font-family: Poppins-Regular;
  color: #000;
  text-decoration: none;
  padding-left: 7px;
}
.ar footer .all .all-grid .list ul li a {
  font-family: shabnam-light;
  padding-right: 25px;
}
#dark footer .all .all-grid .list ul li a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}
footer .all .all-grid .list .df li {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
footer .all .all-grid .list .df li:nth-child(1):before {
  width: 33px;
  height: 33px;
  display: inline-block;
  content: url(./../image/footer/phone_in_talk.svg);
  margin-left: -14px;
  padding-right: 5px;
}
footer .all .all-grid .list .df li:nth-child(2):before {
  width: 33px;
  height: 33px;
  display: inline-block;
  content: url(./../image/footer/Email.svg);
  margin-left: -14px;
  padding-right: 5px;
}
footer .all .all-grid .list .df li:nth-child(3) {
  width: 228px;
  align-items: flex-start;
}
.ar footer .all .all-grid .list .df li:nth-child(3) {
  width: 256px;
}
#dark footer .all .all-grid .list .df li:nth-child(3) {
  color: #fff;
}
footer .all .all-grid .list .df li:nth-child(3):before {
  width: 33px;
  height: 33px;
  display: inline-block;
  content: url(./../image/footer/Location.svg);
  margin-left: -14px;
  padding-right: 5px;
  margin-right: 0;
}
.ar footer .all .all-grid .list .df li:nth-child(3):before {
  width: 33px;
  height: 33px;
  display: inline-block;
  content: url(./../image/footer/Location.svg);
  padding-right: 5px;

}
footer .all .left svg{
  width: 20px;
  height: 20px;
}
.ar footer .all .all-grid .list .df li:nth-child(3):before {
  margin-left: 15px;
}
footer .all .details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 999px;
  padding: .2rem .5rem;
}
#dark footer .all .details {
  background-color: #000;
}
footer .all .left {
  width: 70%;
  display: flex;
  align-items: center;
}
#dark footer .all .left svg {
  fill: #fff;
}
#dark footer .all .left {
  color: #fff;
}
footer .all .left .text {
  padding-left: 8px;
  padding-right: unset;
}
.ar footer .all .left .text {
  padding-right: 8px;
  padding-left: unset;
}
footer .all .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
footer .all .right a {
  width: 18.59px;
  height: 18.59px;
  margin: 12.9px 0;
}
#dark footer .all .right a {
  color: #fff;
  fill: #fff;
}
footer .all .right a svg {
  width: 17.59px !important;
  height: 17.59px !important;
}
footer .all .right .theme {
  display: flex;
  align-items: center;
  width: 70.9px;
  height: 33.91px;
  background: var(--maincolor2);
  padding: 5.4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 27px;
}
.ar footer .all .right .theme {
  direction: ltr;
}
footer .all .right .theme {
  display: flex;
  align-items: center;
  width: 70.9px;
  height: 33.91px;
  background: var(--maincolor2);
  padding: 5.4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 27px;
  z-index: 1;
  position: relative;
}
footer .all .right .theme #sun {
  width: 19px;
  height: 19px;
  z-index: 3;
  cursor: pointer;
}
#dark footer .all .right .theme #sun path {
  fill: #fff;
}
footer .all .right .theme #moon {
  width: 19px;
  height: 19px;
  cursor: pointer;
  z-index: 3;
}
footer .all .right .theme .circle {
  transition: 1s all ease;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 50px;
  position: absolute;
  z-index: 2;
  /* transform: translateX(); */
}
#dark footer .all .right .theme .circle {
  background: #000;
}
/* responsive */

@media only screen and (max-width: 992px) {
  footer .all .all-grid {
    grid-template-columns: auto auto auto;
    margin-top: 45px;
    margin-bottom: 59px;
  }
  footer .all .all-grid .list .df li:nth-child(3) {
    width: 300px;
  }
  footer .all .details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 2rem;
  }
  footer .all .left {
    width: 100%;
    display: flex;
    align-items: center;
  }
  footer .all .all-grid .list {
    margin-bottom: 25px;
  }
  footer .all .right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 768px) {
  footer {
    border-radius: 0;
  }
  footer .all .left svg{
    width: 25px;
    height: 25px;
  }
  footer .all .left .text{
    padding-left: 20px;
    padding-right: unset;
  }
  .ar footer .all .left .text{
    padding-right: 20px;
    padding-left: unset;
  }
  footer .all{
    width: 90%;
  }
  footer .all .all-grid {
    grid-template-columns: auto;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 59px;
  }
  footer .all .all-grid .list p {
    width: 100%;
    text-align: justify;
  }
  footer .all .all-grid .list {
    margin-bottom: 25px;
  }
  footer .all .all-grid .list .df li:nth-child(3) {
    width: 100%;
  }
  footer .all .details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: .5rem 1rem;
    gap: 0.5rem;
  }
  footer .all .left {
    width: 100%;
    display: flex;
    align-items: center;
  }
  footer .all .right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
