#judge{

}
.judge_select{
    width: 120px;
}
.judge-input{
    width: calc(100% - 130px);
}
.grand-parent{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    /* border: 2px solid red; */
}
.judge-parent{
    display: flex;
    flex-wrap: wrap !important;
    height: 565px;
    /* border: 1px solid blue; */
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 1px 1px 20px rgba(79, 79, 79, 0.18);
}
/* right *//* right */
/* right *//* right */


.judge-parent .judge-right{
    width: 50%;
    height: 100%;
    /* border: 1px solid red; */
    padding: 14px;
}
.judge-parent .judge-right h1{
    height: 30px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 22px;
    font-weight: bolder;
}
.judge-parent .judge-right form{
    position: relative;
    width: 100%;
    height: 372px;
    /* border: 1px solid red; */
}
    
.judge-right form>div{
    width: 100%;
    height: 72px;
    /* border: 1px solid purple; */
    margin-bottom: 20px;
}


.judge-right form>div label{
    display: flex;
    height: 19px;
    font-size: 14px;
    margin-bottom: 7px;
    color: #a5a5a5;
    font-size: 14px;
}
    





/* submit-form *//* submit-form */
/* submit-form */
.judge-right form .submit-form{
    width: 100%;
    height: 46px !important;
    /* border: 1px solid red; */
}
.judge-right form .submit-form button{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    background: #f9cc0b;
    color: white;
}











/* option_style *//* option_style */
#judge .option_style{
    direction: ltr;
    display: flex !important;
    justify-content: flex-start;
    column-gap: 10px;
    height: 100%;
    font-size: 12px;
    /* background: gray; */
    
}


/* responsive ریسپانسیو */
/* responsive ریسپانسیو */
/* responsive ریسپانسیو */

/* MAX 640 */
@media screen and (max-width: 640px) {
     body{
        /* background: rgb(209, 200, 204); */
     }
     .judge-parent{
        align-content: flex-start;
        width: 95%;
        justify-content: center;
        height: 465px;
        /* border: 1px solid black; */
        border-radius: 20px;
     }
     
    
    .judge-parent .judge-right{
        width: 90%;
        height: 310px;
        order: 1;
        /* border: 1px solid orange; */
        padding: 0;
    }
    .judge-parent .judge-right h1{
        margin: 0;
        /* border: 1px solid orange; */
        margin-bottom: 20px;
    }
    .judge-parent .judge-right form{
        /* border: 1px solid red; */
    }
    .judge-parent .judge-right form .recently{
        position: fixed;
        width: 90%;
        right: 5%;
        bottom: 20px;
        /* border: 1px solid red; */
    }





}
/* MIN 640 */
@media screen and (min-width: 640px) {
    body{
        /* background: rgb(112, 216, 176); */
     }
    .judge-parent{
      width: 90% !important;
      max-width: 704px !important;
    }
}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 1024px) {
    body{
        /* background: rgb(206, 164, 86); */
     }
     .judge-parent{
        width: 842px !important;
        max-width: 842px !important;
      }
}
@media screen and (min-width: 1280px) {

}
@media screen and (min-width: 1536px) {

}


