/* //////////// register-right ///////////////////*/
#light .register-right{
    background: white ;
}
#dark .register-right{
    background: black ;
}
/* //////////////// h1 ///////////////////////*/
#light .register-right h1{
    color: black;
}
#dark .register-right h1{
   color: white;
}


/* ////////////toggleEye ////////////////*/
#light .register-right form .toggleEye{
    
    color:black ;
}
#dark .register-right form .toggleEye{
    
    color:white ;
}
/* //////////////section label //////////////// */
#light .register-right form>section label{
    color: var(--light-gray-1);
}
#dark .register-right form>section label{
    color: var(--dark-gray-1);
}
/* //////////////submit button///////////// */
#light .register-right form .submit-form button{
    background: var(--light-yellow-1);
    color: white;
}
#dark .register-right form .submit-form button{
    background: var(--dark-yellow-1);
    color: white;
}
/* //////////////// recently //////////////////// */
#light .register-right form  .recently span{
    color: var(--dark-gray-2);
}
#dark .register-right form  .recently span{
    color: var(--light-gray-2);
}