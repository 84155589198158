#socket {
    padding: 3rem 0 2rem 0 !important;
}

#socket .all {
    width: 80%;
    margin: auto;
    /* display: grid; */
    /* gap: 3rem; */
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    position: relative;
    margin-bottom: 1.5rem;
}

#socket .swiper {
    padding: 23px 3px 10px 3px !important;
    direction: rtl !important;
}

#socket .all .grid-item {
    padding: 0 !important;
    position: relative;
}

#socket .all .grid-item:hover .carts {
    box-shadow: 0 1px 10px #0000001b;
}

#socket .all .grid-item .carts {
    padding: 1rem;
    height: 120px;
    overflow: hidden;
    position: relative;
    /* font-family: Poppins-Regular; */
    box-shadow: 0 1px 4px #0000001b;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    cursor: default;
    transition: .25s;
    direction: ltr !important;
}

#dark #socket .all .grid-item .carts {
    box-shadow: 0 1px 10px #ffffff53;
}

#dark #socket .all .grid-item:hover .carts {
    box-shadow: 0 1px 20px #ffffff53;
} 

#socket .all .grid-item .carts .image {
    width: 120px;
    height: 120px;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    margin: auto;
    background: var(--src);
    background-size: cover;
    filter: blur(60px);
}

#socket .all .grid-item .logo {
    width: 65px;
    height: 65px;
    margin: auto;
    position: absolute;
    top: -22px;
    left: 0;
    right: 0;
    border-radius: 50%;
    z-index: 1;
}

#socket .all .grid-item .carts .details {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#socket .all .grid-item .carts .details.dt1 > div:last-of-type {
    direction: ltr;
}

#dark #socket .all .grid-item .carts .details {
    color: #fff;
}

#socket .all .grid-item .carts .details .up {
    color: #15de5f;
}

#socket .all .grid-item .carts .details .down {
    color: #f63f3f;
}

#socket .all .grid-item .carts .time {
    color: var(--maincolorfont1);
    font-size: 14px;
    position: absolute;
    top: 30px;
    left: 15px;
}

#socket .p-up {
    background: url(../../assests/image/price-socket/up.png) no-repeat;
}

#socket .p-down {
    background: url(../../assests/image/price-socket/down.png) no-repeat;
}

#socket .p-up,
#socket .p-down {
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 2px;
}

#socket .seeall {
    position: absolute;
    top: -30px;
    right: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-size: 15px;
}

#dark #socket .seeall {
    color: #fff;
}

#socket .seeall svg {
    width: 20px;
    transition: .25s;
}

#dark #socket .seeall svg {
    fill: #fff;
}

#socket .seeall:hover svg {
    transform: translateX(3px);
}

#socket .all .grid-item .carts .details .number {
    direction: ltr;
}


@media screen and (max-width: 1300px) {
    #socket .all {
        /* grid-template-columns: 1fr 1fr; */
    }
}

@media screen and (max-width: 768px) {
    #socket .all {
        /* grid-template-columns: 1fr; */
    }
}