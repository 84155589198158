#carts-successful{
    position: absolute;
    bottom: 3rem;
    right: 0;
    width: 347px;
    border-radius: 20px 0 0 20px;
    display: flex;
    box-shadow: 0 3px 25px #00000010;
}
#dark #carts-successful{
    box-shadow: 0 3px 25px #ffffff15;
}
#carts-successful .right{
    width: 80%;
    border-left: 4px solid #74C4BA;
    padding: 5px 1rem;
}
#carts-successful .right .title{
    font-family: shabnam-bold;
}
#carts-successful .right .des{
    font-size: 14px;
    font-family: shabnam-light;
    color: #707070;
}
#carts-successful .left{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(270deg,#74C4BA50,#74C4BA00);
}