#dashboard {
    position: relative;
    width: 100%;
    min-height: 100vh;
    user-select: none;
    /* border: 2px solid purple; */
}

/* /////////////////// main ///////////////////////// */
/* /////////////////// main ///////////////////////// */
#dashboard >main {
    min-height: 100vh;

}

/* قسمت میانه */
#dashboard >main .middle {
    /* border: 1px solid blue; */
}

#dashboard > main .middle .middle-right {
    
    border-radius: 20px;
    /* box-shadow: 1px 1px 5px rgb(199, 195, 195); */
    padding: 10px 15px;
    /* border: 1px solid green; */
}








#dashboard >main .middle>section form input {
    /* border: 1px solid black; */
    padding: 7px 15px;
   
    font-size: 18px;

}

#dashboard >main .middle>section form label {
    font-size: 14px;
  
}


#dashboard .middle-left{
    /* border:1px solid  orange; */
}
#dashboard .four_box {
    /* border:1px solid  orange; */
    overflow: auto;
    
}

#dashboard .four_box section {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    /* border: .1px solid green; */
    padding-bottom: 20px;
}

#dashboard .four_box section img {
    position: absolute;
    top: 0;
    right: calc(50% - 29px);
    width: 60px;
    height: 60px;
    z-index: 1;
}

#dashboard .four_box section .content {
    position: relative;
    width: 258px;
    height: 103px;
    /* background: white; */
    border-radius: 12px;
    padding: 10px 16px;
    /* border: 1px solid #edeef1; */
    
    overflow: hidden;
    box-shadow: 0 1px 4px #0000001b;
}

#dashboard .four_box section .content .bg-blur {
    width: 120px;
    height: 120px;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    margin: auto;
    background: var(--src);
    background-size: cover;
    filter: blur(60px);
}

/* /////////////سوابق معاملات اخیر////////////////////////////////// */
#dashboard .business-history {
    min-height: 253px;
    /* box-shadow: 1px 1px 5px rgb(115, 114, 114); */
    border-radius: 15px;
    /* border: 1px solid red; */
    margin-bottom: 100px;
}

#dashboard .business-history .table-parent {
    /* border: 1px solid red; */
    overflow-x: scroll;
    overflow-y: hidden;
}

#dashboard .table-parent th {
    height: 25px;
    /* border: 1px solid red; */
    
}
#dashboard .table-parent th div{
    display: inline-block;
}
#dashboard .table-parent th img{
    display: inline-block;
    width:8px;
    margin: 0 15px;
}

#dashboard .table-parent tr {
    height: 45px;
    margin-bottom: 10px;
    
}


#dashboard .type_buy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 76px;
    height: 35px;
    background: #dbf3f1;
    color: #00c0af;
    border-radius: 10px;
}

#dashboard .type_sale {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 76px;
    height: 35px;
    background: #f3d8d8;
    color: #f63f3f;
    border-radius: 10px;
}













/* ////////////////mobile-total-wealth //////////////*/
#dashboard .mobile-total-wealth {
    height: 44px;
    background: #322e26;
    color: white;
    padding: 0 24px;
}







#dashboard .up {
    color: #15de5f;
}

#dashboard .down {
    color: #f63f3f;
}


/* //////////////////responsive ریسپانسیو///////////////////*/
/* //////////////////responsive ریسپانسیو///////////////////*/
/* //////////////////responsive ریسپانسیو///////////////////*/


/* max 640     mobile-mobile */
@media screen and (max-width:640px) {
    

    #dashboard >main {
        width: 100%;
        padding: 0 15px;
        /* border: 1px solid red; */

    }

    #dashboard .middle {
        width: 100% !important;

    }

    .middle-right,
    .middle-left {
        width: 100% !important;
    }

    .middle-left {

        margin-bottom: 30px;

    }

    .middle-left .four_box {
        width: auto;
        overflow-x: scroll !important;
        overflow-y: hidden;
        /* border: 1px solid purple; */
        margin-bottom: 25px;
    }

    .middle-left .four_box>div {
        height: 162px;
        width: 1100px !important;
        display: flex;
        justify-content: space-between;

        gap: 1rem;
    }


    /* دعوت دوستان */
    .invite-friends {
        justify-content: center;
        height: 140px;
        padding: 10px 10px;
        /* border: 1px solid red; */
    }

    .invite-friends>section:nth-of-type(2) {
        min-width: 307px;
        width: 100%;
    }
    .invite-friends .referral_code{
        font-size: 11px;
    }


    .middle-right {      
        height: 383px;
        padding: 10px 30px;
        margin-bottom: 20px;
        /* border: 1px solid red; */
    }

    #dashboard .business-history .table-parent table {
        width: 1000px !important;
    }


}

/* min 640 sm-sm-sm-sm-sm*/
@media screen and (min-width:640px) {
    #dashboard {
        /* background: rgb(193, 193, 142); */
    }

    #dashboard #mobile-nav {
        height: 75px;
    }

    #dashboard #mobile-nav span {
        width: 46px;
        height: 46px;
    }

    #dashboard >main {
        width: 100%;
        padding: 0 15px;
        /* border: 1px solid red; */

    }

    #dashboard .middle {
        width: 100% !important;
        /* border: 1px solid red; */
    }

    .middle-right,
    .middle-left {
        width: 100% !important;
    }

    .middle-left .four_box {
        height: 320px;
        margin-bottom: 30px;
        /* border: 1px solid black; */
        /* border: 1px solid red; */
    }

    .middle-left .four_box>div {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-wrap: wrap;
    }

    .middle-left .four_box>div>section {
        /* border: 1px solid black; */
        width: 50%;
        height: 50%;
        padding-bottom: 15px !important;
    }

    /* دعوت دوستان */
    .invite-friends {
        align-items: center;
        justify-content: space-between;
        height: 100px;
        padding: 10px 10px;
        /* border: 1px solid red; */
        margin-bottom: 20px;
    }

    .invite-friends>section:nth-of-type(2) {
        height: 46px;
        min-width: 307px;
    }
    .invite-friends .referral_code{
        font-size: 12px;
    }

    /* right */
    .middle-right {
       
        height: 387px;
        padding: 20px 30px;
        margin-bottom: 20px;
        /* border: 1px solid red; */
    }

    #dashboard .business-history .table-parent table {
        width: 1200px;
    }

}

/* min 768 md-md-md-md-md*/
@media screen and (min-width:768px) {
    #dashboard {
        /* background: pink; */
    }

}

/* min 1024 lg-lg*/
@media screen and (min-width:1024px) {
    #dashboard {
        /* background: gray; */
    }

    #dashboard >main {
        padding: 10px 15px;
        width: 82%;
        /* border: 1px solid red; */
    }

    #dashboard >main .middle {
        justify-content: space-between;
        height: 387px;
        /* border: 1px solid red; */
        margin-bottom: 30px;
    }

    #dashboard .middle-left,
    #dashboard .middle-right {
        height: 100% !important;
        width: 48% !important;
        /* border: 1px solid red; */
    }

  

    #dashboard .middle-left .four_box {
        height: 294px;
        margin-bottom: 0px;
        /* border: 1px solid red; */
    }

    #dashboard .middle-left .four_box>div {
        justify-content: space-between;
    }

    #dashboard .middle-left .four_box>div>section {
        width: 47%;
    }

    #dashboard .middle-left .invite-friends {
        justify-content: space-between;

        height: 93px;
        margin-bottom: 0;
        padding: 0;
    }

    #dashboard .middle-left .invite-friends>section:nth-of-type(2) {
        /* border: 1px solid red; */
        padding: 0 7px;
    }
    .invite-friends .referral_code{
        font-size: 14px;
    }
}

/* min 1280   xl-xl-xl-xl*/
@media screen and (min-width:1280px) {
    #dashboard >main {
        padding: 10px 30px;
    }

    #dashboard .middle-left .invite-friends {
        padding: 0 20px;
    }

    #dashboard .business-history .table-parent {
        overflow: hidden;
    }

    #dashboard .business-history .table-parent table {
        width: 100% !important;
    }
}

/* min 1536   2xl-2xl-2xl-2xl*/
@media screen and (min-width:1536px) {}