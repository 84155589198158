#dark #blog {
    color: #fff;
}

/* اسلایدر */
#blog .blog-slider {
    display: flex;
    width: 70%;
    min-height: 450px;
    /* border: 1px solid black; */
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 100px;
    box-shadow: 1px 1px 5px gray;
}

#blog .blog-slider figure {
    width: 70%;
    height: 100%;

}

#blog .blog-slider figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#blog .blog-slider .slider_desc {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#blog .blog-slider .slider_desc {
    padding: 15px;

}

/* ////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// */
/* دوقلو */
#blog .blog-twin {
    display: flex;
    justify-content: space-between;
    width: 70%;
    /* border: 1px solid black; */
    min-height: 250px;
    margin-bottom: 100px;
}

#blog .blog-twin>section {
    width: 45%;
    border-radius: 7px;
    padding-bottom: 1rem;
    overflow: hidden;
    box-shadow: 1px 1px 5px gray;
    /* border: 1px solid black; */

}

/* ////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// */

/* لیست مقالات */
#blog .parent_article_list {
    position: relative;
    width: 100%;
    min-height: 1700px;
    /* border: 1px solid black; */
}

#blog .parent_article_list .bg-gray {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 290px;
    background-image: url(../../assests/image/blog/bg-blog.png);
}

#blog .parent_article_list .content {
    position: relative;
    width: 70%;
    /* border: 1px solid red; */
    margin: 0 auto;

}

#blog .parent_article_list .content .article_list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}


#blog .parent_article_list .content .article_list .article_item {
    border-radius: 7px;
    padding-bottom: 1rem;
    overflow: hidden;
    background: white;
    box-shadow: 1px 1px 5px grey;
    display: flex;
    flex-direction: column;
}

#dark #blog .parent_article_list .content .article_list .article_item {
    background: #000;
}

#blog .parent_article_list .content .article_list .article_item > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* صفحه بندی */
/* صفحه بندی */
/* صفحه بندی */
/* صفحه بندی */
.paginate {
    width: fit-content;
    display: flex;
    /* background: gray; */
    padding: 0 10px;
    column-gap: 10px;
}

.paginate>li {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: #eee;
}

#dark .paginate li {
    background-color: #222;
}

.active-paginate {
    border: 2px solid var(--maincolor2);
    background-color: var(--maincolor2) !important;
    font-weight: bolder;
    color: white;
    pointer-events: none;
}

/* ///////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////// */
#blog .mhd li {
    border: 1px solid white;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}

#blog .activeBtn {
    color: orange !important;
    border: 1px solid orange !important;
    font-weight: bolder;

}


@media screen and (max-width: 768px) {
    #blog .blog-slider figure {
        width: 100%;
    }

    #blog .blog-slider .slider_desc {
        width: 100%;
    }

    #blog .blog-slider {
        width: 85%;
    }

    #blog .blog-twin {
        flex-direction: column;
        gap: 3rem;
        width: 85%;
    }

    #blog .blog-twin>section {
        width: 100%;
    }

    #blog .parent_article_list .content {
        width: 85%;
    }

    #blog .parent_article_list .content .article_list {
        grid-template-columns: 1fr;
    }

    #blog .parent_article_list {
        min-height: unset;
    }
}
