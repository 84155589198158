#everything {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.body {
  margin-bottom: unset;
}
#everything .module1 {
  width: 100%;
  height: 100%;
  background: #0000003b;
  backdrop-filter: blur(10px);
  position: absolute;
  z-index: 20;
}

#everything .module1 .cart {
  width: 636px;
  height: 208px;
  padding: 2rem;
  background: #fff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
#everything .module1 .cart .title {
  font-family: Shabnam-Light !important;
  padding-bottom: 15px;
}
#everything .module1 .cart .code {
  border: 1px solid #edeef1;
  border-radius: 10px;
  padding: 12px 10px;
}
#everything .module1 .cart button {
  width: 282px;
  height: 46px;
  color: #fff;
  background: #f9cc0b;
  text-align: center;
  padding: 10px;
  box-shadow: 5px 7px 11px #f9cc0b49;
  border-radius: 10px;
  margin-top: 20px;
  position: absolute;
  left: 2rem;
}
#everything .module2 {
  width: 100%;
  height: 100%;
  background: #0000003b;
  backdrop-filter: blur(10px);
  position: absolute;
  z-index: 20;
}
#everything .module2 .cart {
  width: 636px;
  height: 408px;
  padding: 2rem;
  background: #fff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
#everything .module2 .cart .top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#everything .module2 .cart .top .filter {
  display: flex;
  align-items: center;
}
#everything .module2 .cart .top .filter .title {
  padding-right: 5px;
}
#everything .module2 .cart .top .back {
  display: flex;
  align-items: center;
  border: 1px solid #edeef1;
  padding: 12px 20px;
  border-radius: 10px;
  cursor: pointer;
}
#everything .module2 .cart .top .back .text {
  padding-right: 5px;
}
#everything .module2 .cart .filde1 {
  width: 100%;
  display: flex;
  align-items: center;
}
#everything .module2 .cart .filde1 div {
  width: 100%;
}
#everything .module2 .cart .filde1 label {
  color: #707070;
  font-size: 14px;
  font-family: Shabnam-Light !important;
}
#everything .module2 .cart .filde1 select {
  width: 100%;
  height: 46px;
  padding: 0 15px;
  border: 1px solid #edeef1;
  margin-top: 10px;
  border-radius: 10px;
}
#everything .module2 .cart .filde1 .first {
  display: flex;
  flex-direction: column;
}
#everything .module2 .cart .filde1 .secend {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
#everything .module2 .cart .filde2 {
  width: 100%;
  display: flex;
  align-items: center;
}
#everything .module2 .cart .filde2 .rmdp-container {
  width: 100%;
}
#everything .module2 .cart .filde2 .rmdp-container input {
  width: 100%;
  height: 46px;
  padding: 0 15px;
  border: 1px solid #edeef1;
  margin-top: 10px;
  border-radius: 10px;
}
#everything .module2 .cart .filde2 .first {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#everything .module2 .cart .filde2 .secend {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
#everything .module2 .cart .btn {
  width: 282px;
  height: 46px;
  color: #fff;
  background: #f9cc0b;
  text-align: center;
  padding: 10px;
  box-shadow: 5px 7px 11px #f9cc0b49;
  border-radius: 10px;
  margin-top: 20px;
  position: absolute;
  left: 2rem;
}
#everything #all {
  width: 82%;
  /* padding: 2rem; */
  padding: 10px 30px;
  position: relative;
  background:#f7f8fa ;
  padding-bottom: 5rem;
  min-height: 100ch;
}
#dark #everything #all{
  background:#141414 ;
}
#everything #all .top {
  display: flex;
  justify-content: space-between;
  font-family: Shabnam;
}
#everything #all .top .filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 116px;
  height: 46px;
  background: #f7f8fa;
  border: 1px solid #edeef1;
  border-radius: 10px;
  cursor: pointer;
}
#everything #all .top .filter .text {
  padding-right: 5px;
}
#everything #all .top .tabs .MuiBox-root.css-1gsv261 {
  width: fit-content;
}
#everything #all .top .tabs button {
  width: 132px;
  font-family: Shabnam;
}
#dark #everything #all .top .tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #fff;
}
#everything
  #all
  .top
  .tabs
  .Mui-selected {
  width: 132px;
  color: #f9cc0b;
  font-family: Shabnam-Bold;
}
#dark
  #everything
  #all
  .top
  .tabs
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #f9cc0b;
}
#everything #all .top .tabs .css-1aquho2-MuiTabs-indicator {
  background: #f9cc0b;
}

#everything #all .body table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#everything #all .body td,
th {
  border-bottom: 1px solid #edeef1;
  /* text-align: left; */
  padding: 8px;
  /* text-align: right; */
  font-family: Shabnam-FD;
}
#dark #everything #all .body td {
  color: #fff;
}
#dark #everything #all .body th {
  color: #fff;
}
#everything #all .body th .list {
  display: flex;
  align-items: center;
  font-family: Shabnam-Light-FD;
  font-size: 12px !important;
  color: #86919e;
}
#everything #all .body th .list img {
  padding-right: 15px;
  cursor: pointer;
  width: 23px;
}
#everything #all .nothing {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: shabnam-light;
}
#everything #all .pay {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
#everything #all .time {
  display: inline-block;
  padding-left: 15px;
}
#everything #all .background {
  width: 104px;
  height: 35px;
  background: #d8f3f1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #00c0af;
  /* cursor: pointer; */
}
.paginate {
  width: fit-content;
  display: flex;
  padding: 0 10px;
  column-gap: 20px;
}
.paginate > li {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
}
@media only screen and (max-width: 1200px) {
  .a-scroll{
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .MuiBox-root p {
     width: 100%; 
    /* overflow-x: scroll !important; */ 
  }
  table {
    width: 1000px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .css-k008qs{
    overflow-x: auto;
  }
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    overflow-x: auto;
    margin-bottom: 5px;
    width: calc(100vw - 4rem);
  }
  .body {
    margin-bottom: 55px;
  }
  #everything {
    height: 100%;
  }
  #everything #all {
    width: 100%;
  }
  #everything .module1 .cart {
    width: 90%;
    height: 238px;
  }
  #everything .module2 .cart {
    width: 90%;
    height: 568px;
  }
  #everything .module1 .cart .code {
    width: 100%;
    overflow: auto;
  }
  #everything #all .nothing {
    bottom: -500px;
  }
}
@media only screen and (max-width: 640px) {
  #everything #all .top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: Shabnam;
  }
  #everything #all .top .tabs .MuiBox-root.css-1gsv261 {
    width: 100%;
  }
  #everything #all .top .tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    width: 50%;
    font-family: Shabnam;
  }
  #everything
    #all
    .top
    .tabs
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    width: 50%;
  }
  #everything #all .top .filter {
    margin-top: 15px;
    width: 100%;
  }

  #everything .module2 .cart .filde1 {
    width: 100%;
    flex-direction: column;
  }
  #everything .module2 .cart .filde2 {
    width: 100%;
    flex-direction: column;
  }

  #everything .module2 .cart .filde1 .secend {
    margin-top: 15px;
    padding: 0;
  }
  #everything .module2 .cart .filde2 .first {
    margin-top: 15px;
  }
  #everything .module2 .cart .filde2 .secend {
    margin-top: 15px;
    padding: 0;
  }
}
.active-paginate {
  background-color: var(--maincolor2) !important;
  border: 2px solid var(--maincolor2);
  color: #fff;
  font-weight: bolder;
  pointer-events: none;
}
#dark .paginate li{
  background: #f7f8fa;
}