#section-two {
    padding: 3rem 0 2rem 0;
}

.m-title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 5rem auto 0 auto;
    width: 80%;
    text-align: center;
}

.m-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

#dark .m-title {
    color: #fff;
}

.m-time-box {
    color: var(--maincolor2);
    display: flex;
    align-items: center;
    gap: 5px;
    animation: live2 2s infinite;
}

.m-time-box i {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: red;
    animation: live 2s infinite;
}

@keyframes live2 {
    0%,100% {
        opacity: 1;
    }
    50% {
        opacity: .7;
    }
}
@keyframes live {
    0%,100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.p-up {
    background: url(../image/price-socket/up.png) no-repeat;
}

.p-down {
    background: url(../image/price-socket/down.png) no-repeat;
}

.p-up,
.p-down {
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 2px;
}

.m-up {
    color: #15de5f !important;
}

.ar .m-up>div {
    width: fit-content;
    direction: ltr;
}

.m-down {
    color: var(--maincolorred) !important;
}

.m-container {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px #00000011;
    overflow: hidden;
    width: 80%;
    margin: auto;
    margin-bottom: 4rem;
    position: relative;
    margin-top: -1rem;
}

#dark .m-container {
    background-color: #000;
    box-shadow: 0 1px 20px #ffffff53;
}

#dark .m-container * {
    color: #fff;
}

/* .m-container::before {
    content: '';
    position: absolute;
    top: 20rem;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 50rem;
    background: url(../image/currency/Bitcoin.png) no-repeat;
    background-size: cover;
    filter: blur(300px);
    z-index: -10;
} */

.m-container button {
    background-color: unset;
    border: none;
    cursor: pointer;
    padding: 0;
    /* font-family: Poppins-Regular; */
}

.m-top {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.m-top::before {
    content: '';
    position: absolute;
    width: calc(100% - 2rem);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 1px dashed #eee;
}

#dark .m-top::before {
    border: 1px dashed #888;
}

.m-top-left {
    display: flex;
    gap: .5rem;
}

.m-top-left button {
    padding: 5px 10px;
    border-radius: 8px;
}

.m-top-left button:hover {
    background-color: #fafafa;
}

#dark .m-top-left button:hover {
    background-color: #222;
}

.m-top-left .active {
    padding: 5px 20px;
    color: var(--maincolor2) !important;
    font-weight: bold;
    background-color: var(--maincolor2light);
    pointer-events: none;
}

#dark .m-top-left .active {
    background-color: #3b2703;
}

.m-top-right {
    display: flex;
    background-color: #f7f7f7;
    border-radius: 8px;
    overflow: hidden;
}

#dark .m-top-right {
    background-color: #444;
}

.m-top-right button {
    padding: 8px 12px;
}

.m-top-right button:hover {
    background-color: #eee;
}

#dark .m-top-right button:hover {
    background-color: #555;
}

.m-top-right .active {
    color: var(--maincolor2) !important;
    position: relative;
    pointer-events: none;
    font-weight: bold;
}

.m-top-right .active::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    border-radius: 999px;
    background-color: var(--maincolor2);
}

.m-bottom {
    display: flex;
    flex-direction: column;
    gap: 7px;
    background-color: #fafafa;
}

#dark .m-bottom {
    background-color: #1d1d1d;
    gap: 5px;
}

.m-bottom>div {
    background-color: #fff;
}

#dark .m-bottom>div {
    background-color: #000;
}

.m-bottom-top {
    display: grid;
    gap: 7px;
    grid-template-columns: 3fr 2fr 2fr 2fr 2fr;
    padding: 1rem;
}

#dark .m-bottom-top {
    margin-top: 5px;
    background-color: #1d1d1d !important;
}

.m-bottom-top img {
    width: 9px;
    margin: 1px 5px 0 5px;
}

#dark .m-bottom-top img {
    filter: invert(1);
}

.m-bottom-top button,
.m-bottom-top span {
    display: flex;
    width: fit-content;
    color: #999;
    font-size: 13px;
}

.m-bottom-top button:hover {
    color: #555;
}

.m-row {
    padding: 1rem;
    display: grid;
    gap: 7px;
    grid-template-columns: 3fr 2fr 2fr 2fr 2fr;
    align-items: center;
    font-size: 15px;
}

.m-row-first {
    display: flex;
    gap: 5px;
    align-items: center;
}

.m-row-first img {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
}

.m-row-last {
    width: 100%;
}

.m-row-last .m-link {
    display: flex;
    /* cursor: pointer; */
    border-radius: 10px;
    gap: 3px;
}

.m-row-last .m-link:hover {
    /* background-color: #fafafa; */
}

#dark .m-row-last .m-link:hover {
    /* background-color: #1d1d1d; */
}

.m-row-last .green,
.m-row-last .red {
    padding: 5px;
    flex-grow: 1;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
}

.m-row-last .green:hover,
.m-row-last .red:hover {
    background-color: #fafafa;
}

#dark .m-row-last .green:hover,
#dark .m-row-last .red:hover {
    background-color: #1d1d1d;
}

.m-row-last .green {
    border: 1px solid var(--maincolor3);
    color: var(--maincolor3) !important;
    border-radius: 10px 0 0 10px;
    /* border-right: 1px dashed #ccc; */
}

.ar .m-row-last .green {
    border-radius: 0 10px 10px 0;
    border-right: 1px solid var(--maincolor3);
    /* border-left: 1px dashed #ccc; */
}

.m-row-last .red {
    border: 1px solid var(--maincolorred);
    color: var(--maincolorred) !important;
    border-radius: 0 10px 10px 0;
    /* border-left: none; */
}

.ar .m-row-last .red {
    border-radius: 10px 0 0 10px;
    border-left: 1px solid var(--maincolorred);
    /* border-right: none; */
}

.m-price-kind {
    color: #ddd;
    font-size: 13px;
}

#dark .m-price-kind {
    color: #666;
}

.m-top-mobile {
    display: none;
}

.m-mobile-title {
    display: none;
}

.css-1nmdiq5-menu {
    border: 1px solid #fff;
}

#dark .wld-img {
    filter: invert(1);
}


@media screen and (max-width: 1300px) {
    .m-bottom-top {
        grid-template-columns: 3fr 2fr 2fr 2fr;
    }
    .m-row {
        grid-template-columns: 3fr 2fr 2fr 2fr;
    }
    .highprice {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .m-container {
        box-shadow: none;
        background-color: #f4f4f4;
        overflow: unset;
    }
    #dark .m-container {
        box-shadow: none;
    }
    .m-top-mobile {
        display: flex;
        gap: 7px;
        align-items: center;
        background-color: #fff;
        font-size: 15px;
        padding-bottom: 14px;
        border-radius: 20px 20px 0 0;
        padding: 10px;
    }
    #dark .m-top-mobile {
        background-color: #000;
    }
    .m-top-mobile>div {
        flex-grow: 1;
    }
    .m-top {
        display: none;
    }
    .m-bottom {
        background-color: unset;
        gap: 2px;
    }
    #dark .m-bottom {
        gap: 3px;
    }
    .m-bottom-top {
        display: none;
    }
    .m-bottom>div {
        background-color: #fff;
    }
    .m-row {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        align-items: start;
    }
    .m-row-first {
        grid-column: 2 span;
    }
    .m-mobile-title {
        margin: 0;
        margin-bottom: 5px;
        display: block;
        font-size: 12px;
        color: #bbb;
    }
    #dark .m-mobile-title {
        color: #999;
    }
    .m-row-last {
        display: block;
    }
    .m-price-kind {
        display: none;
    }
    .highprice {
        display: block !important;
    }
}